import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MANAGEMENT } from '../../constants/routes';
import { Redirect } from 'react-router';
import { compose } from 'react-recompose/dist/Recompose.cjs';
import { MsgContainer, EmptyText } from './styles';
import { Creators as TitleCreators } from '../../store/ducks/title';
import { CircularProgress } from '@material-ui/core';

class EmptyManagement extends Component {
  componentDidMount() {
    this.props.setTitle('Gerenciamento');
  }

  renderLoading = () => {
    const { loading } = this.props.dashboard;

    if (loading) {
      return <CircularProgress size={36} />;
    }

    return null;
  };

  getEmptyMsg = () => {
    const { loading, data } = this.props.dashboard;

    if ((!data.management || !data.management.options || !data.management.options.length) && !loading) {
      return (
        <EmptyText>Por favor, solicite ao administrador para cadastrar uma opção de gerenciamento.</EmptyText>
      );
    }

    return null;
  };

  render() {
    const { data } = this.props.dashboard;

    if (data.management && data.management.options && data.management.options.length) {
      return <Redirect to={`${MANAGEMENT}/${data.management.options[0].url}`} />;
    }

    return (
      <MsgContainer>
        {this.renderLoading()}
        {this.getEmptyMsg()}
      </MsgContainer>
    );
  }
}

const mapStateToProps = ({ dashboard }) => ({ dashboard });

export default compose(connect(mapStateToProps, { ...TitleCreators }))(EmptyManagement);
