import React, { Component, createRef } from 'react';
import { compose } from 'react-recompose/dist/Recompose.cjs';
import { HeaderContent, HeaderText, LogoContainer, ClickContent } from './LeftDrawer.styles';
import MonitorIcon from 'react-feather/dist/icons/monitor';
import FolderIcon from 'react-feather/dist/icons/folder';
import LogoutIcon from 'react-feather/dist/icons/log-out';
import CloseIcon from 'react-feather/dist/icons/x';
import SettingsIcon from 'react-feather/dist/icons/settings';
import EditIcon from 'react-feather/dist/icons/edit';
import AlertTriangleIcon from 'react-feather/dist/icons/alert-triangle';
import OpenIcon from 'react-feather/dist/icons/arrow-right';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from 'react-pro-sidebar';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DASHBOARD, SETTINGS, MANAGEMENT } from '../../constants/routes';
import { ADMIN, BUCKET_PATH, LDAP_IS_MUST_SELECT_GROUP } from '../../constants/';
import { settingsOptions } from '../../utils/settings';
import { Button, IconButton, Tooltip, CircularProgress } from '@material-ui/core';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import { Creators as ChangeIconCreators } from '../../store/ducks/change_icon';
import { Creators as AnomalyManagementCreators } from '../../store/ducks/anomaly_management';
import { getTheme } from '../../utils/theme';

class LeftDrawer extends Component {
  constructor(props) {
    super(props);
    const tenant = localStorage.getItem('tenant');
    this.logoUrlPathInitialState = `${BUCKET_PATH}${tenant}.png`;
    this.state = {
      collapsed: false,
      fileUploaded: null,
      logoUrlPath: `${this.logoUrlPathInitialState}?${new Date().getTime()}`,
      sideIconOpacity: 1,
    };
    this.logoWidth = '100%';
    this.logoMaxWidth = 60;
    this.logoRef = createRef();
    this.changeLogoButtons = [
      {
        name: 'Confirmar troca',
        component: (
          <IconButton size="medium" style={{ padding: 0 }} onClick={this.sendFile}>
            <AddIcon style={{ color: '#4E944F' }} />
          </IconButton>
        ),
      },
      {
        name: 'Cancelar troca',
        component: (
          <IconButton size="medium" style={{ padding: 0 }} onClick={this.resetLogoState}>
            <RemoveIcon color="error" />
          </IconButton>
        ),
      },
    ];
  }

  onCollapse = () => this.setState({ collapsed: !this.state.collapsed });

  resetLogoState = () => {
    this.setState({ fileUploaded: null, logoUrlPath: `${this.logoUrlPathInitialState}?${new Date().getTime()}` });
  };

  sendFile = () => {
    const { fileUploaded } = this.state;
    const formData = new FormData();
    formData.append('new_logo', fileUploaded);

    this.props.postChangeIcon(formData);
    this.resetLogoState();
  };

  componentDidUpdate() {
    const theme = getTheme(null, null);

    if (theme?.sidemenu?.color && theme?.sidemenu?.list_color) {
      const element = document.querySelector('.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout');
      const listItem = document.querySelector('.react-slidedown.pro-inner-list-item');

      if (element) {
        element.style.backgroundColor = theme.sidemenu.color;
      }

      if (listItem) {
        listItem.style.backgroundColor = theme.sidemenu.list_color;
      }
    }
  }

  renderMenuHeaderOpened = () => {
    const { data } = this.props.dashboard;
    const theme = getTheme(null, null);
    const isShowCompanyName = theme?.is_show_company_name === false ? false : true;

    if (isShowCompanyName) {
      return (
        <>
          <HeaderContent>
            <HeaderText
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {localStorage.getItem(LDAP_IS_MUST_SELECT_GROUP) !== 'true' ? (
                <ClickContent onClick={this.goToHome}>
                  {data?.title || localStorage.getItem('tenant').toUpperCase()}
                </ClickContent>
              ) : null}
              <ClickContent onClick={this.onCollapse}>
                <CloseIcon />
              </ClickContent>
            </HeaderText>
          </HeaderContent>
          <HeaderContent>
            <HeaderText>{this.renderLogo()}</HeaderText>
          </HeaderContent>
        </>
      );
    }


    if (!isShowCompanyName) {
      return (
        <HeaderContent>
          <HeaderText
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <ClickContent onClick={this.goToHome}>
              {localStorage.getItem('tenant').toUpperCase()}
            </ClickContent>
            <ClickContent style={{ alignItems: 'start' }} onClick={this.onCollapse}>
              <CloseIcon />
            </ClickContent>
          </HeaderText>
        </HeaderContent>
      );
    }

  };

  renderMenuHeaderClosed = () => (
    <div>
      <HeaderContent>
        <HeaderText
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ClickContent>
            <OpenIcon onClick={this.onCollapse} />
          </ClickContent>
        </HeaderText>
      </HeaderContent>
      <HeaderContent style={{ display: 'flex' }}>
        <HeaderText>
          {this.renderLogo()}
          <div onClick={this.onCollapse}></div>
        </HeaderText>
      </HeaderContent>
    </div>
  );

  goToHome() {
    window.history.pushState({}, null, '/');
    window.location.reload();
  }

  async logout() {
    window.history.pushState({}, null, '/');
    window.location.reload();
    localStorage.clear();
    await Auth.signOut();
  }

  handleChangeLogoCLick = _event => {
    this.logoRef.current.click();
  };

  handleLogoChange = event => {
    const fr = new FileReader();

    fr.onload = _ => {
      this.setState({ logoUrlPath: fr.result });
    };
    fr.readAsDataURL(event.target.files[0]);

    this.setState({ fileUploaded: event.target.files[0] });
  };

  renderLogo = () => {
    const { sideIconOpacity, fileUploaded, logoUrlPath, collapsed } = this.state;
    const { loading } = this.props.change_icon;
    const ldapIsMustSelectGroup = localStorage.getItem(LDAP_IS_MUST_SELECT_GROUP);

    if (ldapIsMustSelectGroup === 'true') {
      return null;
    }

    return (
      <>
        {loading ? (
          <CircularProgress
            size={this.logoWidth}
            style={{ maxWidth: collapsed ? this.logoMaxWidth * 0.7 : this.logoMaxWidth }}
          />
        ) : (
          <LogoContainer>
            <Tooltip title="Clique para trocar a logo da sua empresa">
              <Button
                style={{ padding: 0, backgroundColor: 'transparent', opacity: sideIconOpacity }}
                onClick={this.handleChangeLogoCLick}
                onMouseEnter={_ => this.setState({ sideIconOpacity: 0.7 })}
                onMouseLeave={_ => this.setState({ sideIconOpacity: 1 })}
                size="small"
              >
                <img
                  src={logoUrlPath}
                  alt="Home"
                  width={this.logoWidth}
                  style={{ maxWidth: collapsed ? this.logoMaxWidth * 0.7 : this.logoMaxWidth }}
                  id="logo-img-id"
                />
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  ref={this.logoRef}
                  onChange={this.handleLogoChange}
                />
              </Button>
            </Tooltip>
            {fileUploaded
              ? this.changeLogoButtons?.map((btn, index) => (
                <Tooltip key={`${btn.name}_${index}`} title={btn.name}>
                  {btn.component}
                </Tooltip>
              ))
              : null}
          </LogoContainer>
        )}
      </>
    );
  };

  render() {
    const theme = getTheme(null, null);
    const { collapsed } = this.state;
    const { data } = this.props.dashboard;

    return (
      <ProSidebar id="leftDrawerId" style={{ float: 'left', height: '100vh' }} collapsed={collapsed}>
        <SidebarHeader>{collapsed ? this.renderMenuHeaderClosed() : this.renderMenuHeaderOpened()}</SidebarHeader>
        <SidebarContent>
          <Menu>
            {data && data.folders ? (
              <>
                {data.folders.map((folder, idxFolder) => (
                  <SubMenu title={folder.label} key={idxFolder} icon={<FolderIcon color={theme.icon_color_sidemenu} />}>
                    {folder.dashboards && folder.dashboards.length
                      ? folder.dashboards.map((dashboard, idxDashboard) => (
                        <MenuItem key={`${idxFolder}_${idxDashboard}`} icon={<MonitorIcon color={theme.icon_color_sidemenu} />}>
                          <Link to={`${DASHBOARD}/${dashboard.dashboard_id}`} />
                          {dashboard.label}
                        </MenuItem>
                      ))
                      : null}
                  </SubMenu>
                ))}
              </>
            ) : null}
            {data && data.management && (
              <SubMenu title={data.management.label} icon={<EditIcon color={theme.icon_color_sidemenu} />}>
                {data.management.options?.map((opt, index) => (
                  <MenuItem key={`${opt.label}_${index}`} icon={<AlertTriangleIcon color={theme.icon_color_sidemenu} />}>
                    <Link to={`${MANAGEMENT}/${opt.url}`} />
                    {opt.label}
                  </MenuItem>
                ))}
              </SubMenu>
            )}
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu>
            {localStorage.getItem('tenantRole') === ADMIN ? (
              <SubMenu title="Configurações" key={SETTINGS} icon={<SettingsIcon color={theme.icon_color_sidemenu} />}>
                {Object.entries(settingsOptions)?.map(([_optKey, optValue], index) => (
                  <MenuItem icon={optValue.icon} key={`${optValue.name}_${index}`}>
                    {optValue.name}
                    <Link to={optValue.linkTo} />
                  </MenuItem>
                ))}
              </SubMenu>
            ) : null}
            <MenuItem onClick={this.logout} icon={<LogoutIcon color={theme.icon_color_sidemenu} />}>
              Sair
            </MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>
    );
  }
}

const mapStateToProps = ({ dashboard, change_icon }) => ({ dashboard, change_icon });

export default compose(connect(mapStateToProps, { ...ChangeIconCreators, ...AnomalyManagementCreators }))(
  LeftDrawer
);
