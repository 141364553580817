import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { ADMIN, USER, BUCKET_PATH } from '../constants';
import { Auth } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';

export function urlParam(obj) {
  return Object.keys(obj)
    .map(key => `${key}=${encodeURIComponent(obj[key])}`)
    .join('&');
}

export function formatMoney(amount, decimalCount = 2, decimal = '.', thousands = ',') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
}

export function formatKNumber(num) {
  if (Math.abs(num) > 999 && Math.abs(num) < 999999) {
    return Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k';
  } else if (Math.abs(num) > 999999) {
    return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + 'kk';
  }

  return Math.sign(num) * Math.abs(num);
}

export function exportCSV(header, rows, filename) {
  const timestampFormat = 'DD_MM_YYYY_HH_MM_SS';
  const outFilename = `${filename}_${moment().format(timestampFormat)}.csv`;

  let parsedHeader = header.join(';');
  let csvContent = parsedHeader + '\r\n';

  rows.forEach(row => {
    const rowParsed = _.values(row).join(';');
    csvContent += rowParsed + '\r\n';
  });

  const blob = new Blob(
    [
      //UTF-8 BOM does the trick for Excel
      new Uint8Array([0xef, 0xbb, 0xbf]),
      csvContent,
    ],
    { encoding: 'UTF-8', type: 'text/csv;charset=UTF-8' }
  );

  const url = window.URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', outFilename);
  document.body.appendChild(link);
  link.click();
}

export const downloadFromURL = url => {
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', 'file.csv');
  document.body.appendChild(link);
  link.click();
};

export const isValidDate = d => {
  return d instanceof Date && !isNaN(d);
};

export const getDateYYMMDDDD = date => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = '0' + dt;
  }
  if (month < 10) {
    month = '0' + month;
  }

  return `${year}-${month}-${dt}`;
};

export const getUserData = async () => {
  let data = {};
  let role = USER;
  let groups = [];
  let tenant = null;
  let adminList = [];
  let tenantList = [];
  let session = null;
  let username = '';

  try {
    session = await Auth.currentSession();
    groups = session.idToken.payload['cognito:groups'];
    username = session.idToken.payload['cognito:username'];

    if (groups.length) {
      adminList = groups.filter(group => group.trim().toUpperCase() === ADMIN);
      tenantList = groups.filter(group => group.trim().toUpperCase() !== ADMIN);
    }

    if (tenantList && tenantList.length) {
      tenant = tenantList[0];
    }

    if (adminList && adminList.length) {
      role = ADMIN;
    }

    if (tenant) {
      tenant = tenant.toString().replace(`${process.env.REACT_APP_USERPOOLID}_`, '');
    }

    data = {
      name: session.idToken.payload.name,
      email: session.idToken.payload.email,
      tenant,
      role,
      username,
    };
  } catch (e) {
    console.error('[getUserData] Error', e);
  }

  return data;
};

export function getTvModeUrls(links) {
  let urls = [];

  if (!links || !links.length) {
    return urls;
  }

  for (let i = 0; i < links.length; i++) {
    const folder = links[i];

    if (!folder || !folder.links) {
      return;
    }

    for (let j = 0; j < folder.links.length; j++) {
      const link = folder.links[j];

      if (link && link.tv_mode && link.url) {
        urls.push(link.url);
      }
    }
  }

  return urls;
}

export const setCustomFavicon = (overrideTenant) => {
  const tenant = localStorage.getItem('tenant') || overrideTenant;

  if (!tenant) return;

  const elFavicon = document.querySelector("link[rel~='icon']");

  if (elFavicon) {
    elFavicon.href = `${BUCKET_PATH}${tenant}.png?${new Date().getTime()}`;
  }
};

export function withAuthenticator(Component) {
  function ComponentWithAuthenticatorProp(props) {
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    localStorage.setItem('awsAuthStatus', authStatus);
    return <Component {...props} authenticatorStatus={authStatus} />;
  }

  return ComponentWithAuthenticatorProp;
}

export function divideArray(arr, elementsPerIndex = 2) {
  const copy = [...arr];

  return new Array(Math.ceil(copy.length / elementsPerIndex)).fill().map(() => copy.splice(0, elementsPerIndex));
}

// \u00A0 == &nbsp;
export const noBreakLineSpace = str =>
  typeof str === 'string' || str instanceof String ? str.split(' ').join('\u00A0') : str;

export const checkIfObjectIsEmpty = obj =>
  obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;

export const csvStringFromArrayOfObjects = (header, array, separator = ';') =>
  [
    header,
    ...array.map(item => {
      const newArray = [];

      Object.values(item).forEach(value => newArray.push(value));

      return newArray;
    }),
  ]
    .map(e => e.join(separator))
    .join('\n');


export const getTenantByHost = () => {
  const host = window.location.host.split(':')[0]; // Remove the port from the host
  const subdomain = host.split('.')[0].replace(/-dev|-prod/g, '');

  if (host === 'localhost') {
    return 'oktopus';
  }

  return subdomain;
}