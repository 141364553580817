import { createTheme } from '@material-ui/core/styles';
import { getTheme } from '../../utils/theme';

const customTheme = getTheme(null, null);

export const filterableColor = '#f8f8f8'
export const measuresColor = 'rgb(240, 224, 224)'
export const lightGrayContent = '#f4f8fc';
export const defaultBackground = customTheme.background_color;
export const baseColor = customTheme.base_color;
export const baseColorLight = '#41A2FF';
export const fontFamily = 'Mada,Hind Madurai,sans-serif';

export const primary = {
  main: customTheme.base_color,
  dark: customTheme.base_color,
  light: '#CF1D49',
  contrastText: '#ffffff',
  listBackground: '#d26a6b',
};

export const secondary = {
  main: '#000',
  dark: customTheme.base_color,
  light: '#CF1D49',
  contrastText: '#ffffff',
  listBackground: '#F9FBA6',
};

export const styledTable = {
  tbRow: {
    '& td': {
      backgroundImage: 'linear-gradient(to right, #707070 40%, rgba(255, 255, 255, 0) 0%)',
      backgroundPosition: 'bottom',
      backgroundSize: '.91rem .6px',
      backgroundRepeat: 'repeat-x',
      padding: '10px 20px 10px',
      borderBottom: 'none'
    }
  },
  tbCell: {
    '& th': {
      textAlign: 'initial',
      borderBottom: '2px solid #707070',
      paddingTop: '1.5rem',
      color: '#000',
      fontSize: '.7rem',
      padding: '0 20px 0 20px'
    }
  },
  tbScroll: {
    '& tbody': {
      display: 'block',
      height: 400,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 8,
        height: 8,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#9D9D9D',
        borderRadius: 10,
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#E9E9E9',
        borderRadius: 10,
      },
    },
    '& thead ,& tbody > tr': {
      display: 'table',
      width: '100%',
      tableLayout: 'fixed'
    },
  }
}

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontSize: 12,
    fontFamily: [fontFamily].join(','),
    h3: {
      "&.appTitle": {
        marginBottom: 24,
      },
    },
  },
  palette: {
    primary,
    secondary,
  },
  overrides: {
    MuiTableRow: {
      root: {
        '&.compact': {
          height: 32
        }
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: baseColor
        }
      },
    },
    MuiFormControlLabel: {
      root: {
        fontSize: '12px',
        fontFamily: fontFamily,
        marginTop: '-0.5rem'
      }
    },
    MuiTab: {
      root: {
        border: '2px solid rgba(255,255,255,0)',
        borderRadius: 36,
      }
    },
    MuiButton: {
      contained: {
        "&$containedPrimary": {
          "&:hover": {
            color: '#fff'
          }
        }
      }
    },
    MuiTabs: {
      fixed: {
        width: '40%',
      },
    },
    MuiChip: {
      outlinedPrimary: {
        border: "1px solid #A9A9A9"
      },
      colorSecondary: {
        color: '#000',
        backgroundColor: secondary.listBackground
      }
    }
  }
});

export const selectStyle = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    fontSize: 12,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: baseColor,
    padding: 5
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    borderRadius: '0 8px 8px 0'

  }),
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      color: baseColor,
      padding: 5,
      ":hover": {
        color: baseColor
      }
    }
  },
  multiValue: (provided) => ({
    ...provided,
    borderRadius: 16
  }),
  groupHeading: (provided) => ({
    ...provided,
    color: baseColor
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: 30
  }),
  control: (provided, state) => {
    return {
      ...provided,
      boxShadow: state.isFocused || state.isHovered ? `0 0 0 1px ${baseColor}` : null,
      "&:hover": {
        borderColor: baseColor,
      },
      borderWidth: 1,
      minHeight: 30
    }
  },
  option: (provided, state) => {
    let color;
    let background;

    if (state.isFocused && !state.isSelected) {
      color = baseColor;
      background = '#EEE';
    }

    if (state.isSelected) {
      color = '#FFF';
      background = baseColor;
    }

    return {
      ...provided,
      color,
      background,
      ":active": {
        color: '#FFF',
        backgroundColor: baseColor
      }
    }
  }
}

export default theme;