import React, { Component } from 'react';
import { connect } from 'react-redux';
import { managementOptions } from '../../utils/management';
import { compose } from 'react-recompose/dist/Recompose.cjs';
import EmptyManagement from '../EmptyManagement';
import { Creators as TitleCreators } from '../../store/ducks/title';
import { CircularProgress } from '@material-ui/core';

class Management extends Component {
  componentDidMount() {
    this.props.setTitle('Gerenciamento');
  }

  renderLoading = () => {
    const { loading } = this.props.dashboard;

    if (loading) {
      return <CircularProgress size={36} />;
    }

    return null;
  };

  render() {
    const { loading, data } = this.props.dashboard;
    const { option } = this.props.match.params;

    if (!loading && (!data.management || !data.management.options || !data.management.options.length)) {
      return <EmptyManagement />;
    }

    if (option in managementOptions) return managementOptions[option].component;

    this.props.history.push('/dashboard');
    window.location.reload();

    return <></>;
  }
}

const mapStateToProps = ({ dashboard }) => ({ dashboard });

export default compose(connect(mapStateToProps, { ...TitleCreators }))(Management);
