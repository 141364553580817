import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
  dashboardRequest: [],
  getDashboards: ['filter'],
  dashboardSuccess: ['data'],
  dashboardError: ['err'],
  setDashboard: ['dashboard_id', 'is_multi_tenant']
});

/* Initial State */

export const INITIAL_STATE = Immutable({
  data: {},
  loading: false,
  error: false
});


/* Reducers */

export const request = state => state.merge({ loading: true });

export const success = (state, { data }) => state.merge({ data, error: false, loading: false });

export const error = state => state.merge({ loading: false, error: true });

export const set = (state, { dashboard_id, is_multi_tenant }) => state.merge({ dashboard_id, is_multi_tenant });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.DASHBOARD_REQUEST]: request,
  [Types.DASHBOARD_SUCCESS]: success,
  [Types.DASHBOARD_ERROR]: error,
  [Types.SET_DASHBOARD]: set
});
