import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import * as constants from './constants/routes';
import Settings from './components/Settings';
import Dashboard from './components/Dashboard';
import RouteRedirector from './components/RouteRedirector';
import MetabaseCallback from './components/MetabaseCallback';
import NotFound from './components/NotFound';
import EmptyDash from './components/EmptyDash';
import EmptyManagement from './components/EmptyManagement';
import Management from './components/Management';
import { ADMIN } from './constants';
import LdapGroupDialog from './components/LdapGroupDialog';

class Routes extends Component {
  render() {
    return (
      <Switch>
        {localStorage.getItem('tenantRole') === ADMIN ? (
          <Route path={`${constants.SETTINGS}/:option`} component={Settings} />
        ) : null}
        <Route path={`${constants.DASHBOARD}/:id`} component={Dashboard} />
        <Route path={constants.DASHBOARD} component={EmptyDash} />
        <Route path={`${constants.MANAGEMENT}/:option`} component={Management} />
        <Route path={constants.MANAGEMENT} component={EmptyManagement} />
        <Route path={constants.LDAP_GROUP_SELECT} component={LdapGroupDialog} />
        <Route path={constants.METABASE_CALLBACK} component={MetabaseCallback} />
        <Route path={constants.REDIRECT} component={RouteRedirector} />
        <Redirect from={constants.ROOT} to={constants.REDIRECT} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default Routes;
