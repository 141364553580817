import React, { Component } from 'react';
import { Typography } from '@material-ui/core';

class NotFound extends Component {
  render() {
    return (
      <div>
        <Typography>Não Encontrado</Typography>
      </div>
    );
  }
}

export default NotFound;