import { compose } from 'react-recompose/dist/Recompose.cjs';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import * as queryString from 'query-string';
import { CircularProgress } from '@material-ui/core';
import ErrorIcon from 'react-feather/dist/icons/alert-triangle';
import SuccessIcon from 'react-feather/dist/icons/check-circle';
import { Creators as MetabaseCallbackCreators } from '../../store/ducks/metabase_callback';
import { CallbackMsgContainer, MsgText } from './styles';

class MetabaseCallback extends Component {

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);

    this.props.postMetabaseCallback(params);
  }

  renderLoading = (loading) => {
    if (!loading) return null;

    return (
      <CallbackMsgContainer>
        <CircularProgress size={36} />
        <MsgText>
          Processando requisição. Por favor, aguarde...
        </MsgText>
      </CallbackMsgContainer>
    )
  }

  renderSuccess = (success) => {
    if (!success) return null;

    return (
      <CallbackMsgContainer>
        <SuccessIcon size={36} color={'#69BF7A'} />
        <MsgText>
          Solicitação processada com sucesso! Esta aba será fechada automaticamente.
        </MsgText>
      </CallbackMsgContainer>
    )
  }

  renderError = (error) => {
    if (!error) return null;

    return (
      <CallbackMsgContainer>
        <ErrorIcon size={36} color={'#FEB37A'} />
        <MsgText>
          Não foi possível processar essa solicitação.
        </MsgText>
      </CallbackMsgContainer>
    )
  }

  render = () => {
    const { loading, success, error } = this.props.metabase_callback;

    return (
      <>
        {this.renderLoading(loading)}
        {this.renderError(error)}
        {this.renderSuccess(success)}
      </>
    )
  }
}

const mapStateToProps = ({ tenant, metabase_callback }) => ({ tenant, metabase_callback });

export default compose(
  connect(mapStateToProps, { ...MetabaseCallbackCreators })
)(MetabaseCallback);