import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './ducks';
import rootSaga from './sagas';

import { composeWithDevTools } from 'redux-devtools-extension';
import Immutable from 'seamless-immutable';
import { Mixpanel } from '../utils/mixpanel';

const sagaMiddleware = createSagaMiddleware();

const logger = () => next => action => {
  if (
    action.type.indexOf('SUCCESS') === -1 &&
    action.type.indexOf('ERROR') === -1 &&
    action.type.indexOf('GET_') === -1
  ) {
    try {
      Mixpanel.track(action.type);
    } catch (err) {
      console.log('[Mixpanel] Error to track events');
    }
  }

  return next(action);
};

const middlewares = [logger, sagaMiddleware];

const configureStore = initialState => {
  return createStore(rootReducer, Immutable(initialState), composeWithDevTools(applyMiddleware(...middlewares)));
};

const store = configureStore();

sagaMiddleware.run(rootSaga);

export default store;
