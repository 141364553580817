import { ENEL, OKTOPUS, CESAN, getTenant } from "./tenant";

export const getTheme = (amplifyTokens, tenant) => {
  const tenantWithCompany = getTenant(tenant);
  const company = tenantWithCompany.company;

  const customTheme = {
    [ENEL]: {
      base_color: '#E5E5E5',
      icon_color_sidemenu: '#000',
      icon_color_content: '#FFF',
      background_color: '#000',
      text_color: '#FFF'
    },
    [OKTOPUS]: {
      base_color: '#a192fd',
      icon_color_sidemenu: '#FFF',
      icon_color_content: '#000',
      background_color: '#F4F8FC',
      text_color: '#000'
    },
    [CESAN]: {
      project_name: 'Scora Acqua',
      is_show_company_name: false,
      base_color: '#a192fd',
      icon_color_sidemenu: '#FFF',
      icon_color_content: '#000',
      background_color: '#FFF',
      text_color: '#000',
      login: {
        logo_width: '15vw',
        color: amplifyTokens?.colors.blue['80'],
        color_hover: amplifyTokens?.colors.blue['90']
      },
      sidemenu: {
        color: '#005290',
        list_color: '#013257'
      }
    }
  }

  return customTheme[company];
}