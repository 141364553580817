import styled from 'styled-components';


export const MsgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50%;
`;


export const EmptyText = styled.div`
  color: #D0D0D0;
  font-size: 2rem;
  max-width: 40%;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
`;