import Immutable from 'seamless-immutable';
import { createReducer, createActions } from 'reduxsauce';
import { getTenant } from '../../utils/tenant';

const INITIAL_STATE = Immutable({
  title: null
});

/* Types & Action Creators */

export const { Types, Creators } = createActions({
  setTitle: ['title']
});

/* Reducers */

export const set = (state, { title }) => {
  const tenant = getTenant();

  window.document.title = `${tenant.name} - ${title}`
  return state.merge({ title });
}

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.SET_TITLE]: set,
});