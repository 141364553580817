import { compose } from 'react-recompose/dist/Recompose.cjs';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import AnomaliesList from '../AnomaliesList';

class AnomalyManagement extends Component {
  constructor(props) {
    super(props);
    const { data } = this.props.dashboard;
    const folders = data.folders.find(fd => fd.label === 'Distribuição');
    this.activeTypeOptions = [];

    folders.dashboards.forEach(dash =>
      this.activeTypeOptions.push({
        label: dash.label,
        value: dash.label.toLowerCase(),
      })
    );
    this.statusFilterSelectOptions = [
      { label: 'Confirmado', value: 'Confirmado' },
      { label: 'Rejeitado', value: 'Rejeitado' },
      { label: 'Aguardando verificação', value: 'Aguardando verificação' },
    ];
  }

  render = () => {
    const { loading, data } = this.props.anomaly_management;

    return (
      <AnomaliesList
        header={data?.count ? Object.keys(data.data[0]) : []}
        tableTitle="Gestão de anomalias"
        activeTypeOptions={this.activeTypeOptions}
        statusFilterSelectOptions={this.statusFilterSelectOptions}
        anomaly_data={data?.count ? data.data : []}
        isLoading={loading}
        totalDataLength={data?.count}
        sortableTable
      />
    );
  };
}

const mapStateToProps = ({ dashboard, anomaly_management }) => ({ dashboard, anomaly_management });

export default compose(connect(mapStateToProps, null))(AnomalyManagement);
