import styled from 'styled-components';

export const colorHexOptions = {
  blue: '#002B5B',
  black: '#100F0F',
  red: '#820000',
  green: '#224B0C',
  yellow: '#FFB200',
  grey: '#576F72',
};

export const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5em;
`;

export const EmptyText = styled.h1`
  color: ${props => props.color};
  font-size: 2rem;
  text-align: center;
  padding-top: 3em;
`;
