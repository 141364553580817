import React from 'react';

// .../mangement
import AnomalyManagement from '../components/AnomalyManagement';

export const managementOptions = {
  anomaly: {
    component: <AnomalyManagement />,
  },
};
