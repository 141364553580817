import { compose } from 'react-recompose/dist/Recompose.cjs';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { settingsOptions } from '../../utils/settings';
import { Creators as TitleCreators } from '../../store/ducks/title';

class Settings extends Component {
  componentDidMount() {
    this.props.setTitle('Configurações');
  }

  render() {
    const { option } = this.props.match.params;

    if (option in settingsOptions) return settingsOptions[option].component;

    this.props.history.push('/dashboard');
    window.location.reload();

    return <></>;
  }
}

const mapStateToProps = null;

export default compose(connect(mapStateToProps, { ...TitleCreators }))(Settings);
