import { Creators } from '../ducks/metabase_callback';
import { Creators as TitleCreators } from '../ducks/title';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import api from '../../services/api';

const closeTab = () => {
  setTimeout(() => window.close(), 4000);
}

export function* postMetabaseCallback({ params }) {
  let newParams = { ...params };

  delete newParams.url;

  try {
    let response;

    yield put(TitleCreators.setTitle('Processando...'));
    yield put(Creators.metabaseCallbackRequest());
    response = yield call(api.post, params.url, newParams);

    yield put(Creators.metabaseCallbackSuccess(response.data));


    yield put(TitleCreators.setTitle('Sucesso no envio'));
    yield put(toastrActions.add({
      type: 'success',
      title: 'Sucesso',
      message: 'Solicitação processada com sucesso! Esta aba será fechada automaticamente.',
      options: {
        onHideComplete: closeTab()
      }
    }));
  } catch (err) {
    console.log(err);
    yield put(TitleCreators.setTitle('Erro no envio'));
    yield put(Creators.metabaseCallbackError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao enviar solicitação. Por favor, entre em contato com o suporte.'
    }));
  }
}