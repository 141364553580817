import { all, takeLeading, takeLatest } from 'redux-saga/effects';

import { Types as TenantTypes } from '../ducks/tenant';
import { Types as DashboardTypes } from '../ducks/dashboard';
import { Types as AnomalyManagementTypes } from '../ducks/anomaly_management';
import { Types as MetabaseCallbackTypes } from '../ducks/metabase_callback';
import { Types as UserRegisterTypes } from '../ducks/user_register';
import { Types as ChangeIconTypes } from '../ducks/change_icon';

import { setTenant } from './tenant';
import { getDashboards } from './dashboard';
import { getAnomalies, postAnomalies } from './anomaly_management';
import { postMetabaseCallback } from './metabase_callback';
import { addUser, getUsers, reSendEmail, deleteUsers, editPermission, editAttribute } from './user_register';
import { postChangeIcon } from './change_icon';

export default function* rootSaga() {
  return yield all([
    takeLeading(TenantTypes.SET_TENANT, setTenant),
    takeLatest(DashboardTypes.GET_DASHBOARDS, getDashboards),
    takeLatest(AnomalyManagementTypes.GET_ANOMALIES, getAnomalies),
    takeLatest(AnomalyManagementTypes.POST_ANOMALIES, postAnomalies),
    takeLatest(MetabaseCallbackTypes.POST_METABASE_CALLBACK, postMetabaseCallback),
    takeLatest(UserRegisterTypes.ADD_USER, addUser),
    takeLatest(UserRegisterTypes.GET_USERS, getUsers),
    takeLatest(UserRegisterTypes.RE_SEND_EMAIL, reSendEmail),
    takeLatest(UserRegisterTypes.DELETE_USERS, deleteUsers),
    takeLatest(UserRegisterTypes.EDIT_PERMISSION, editPermission),
    takeLatest(UserRegisterTypes.EDIT_ATTRIBUTE, editAttribute),
    takeLatest(ChangeIconTypes.POST_CHANGE_ICON, postChangeIcon),
  ]);
}
