import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  userRegisterRequest: [],
  userRegisterSuccess: ['data'],
  userRegisterError: ['err'],
  addUser: ['request'],
  getUsersRequest: [],
  getUsersSuccess: ['data'],
  getUsersError: ['err'],
  getUsers: ['tenant'],
  getUsersInit: [],
  getUsersFilter: ['filter'],
  getUsersSortBy: ['data', 'sort'],
  getUsersExpand: ['expandedRow'],
  getUsersInitTable: [],
  reSendEmailRequest: [],
  reSendEmailSuccess: ['data'],
  reSendEmailError: ['err'],
  reSendEmail: ['user'],
  deleteUsersRequest: [],
  deleteUsersSuccess: ['data'],
  deleteUsersError: ['err'],
  deleteUsers: ['users'],
  editPermissionRequest: [],
  editPermissionSuccess: ['data'],
  editPermissionError: ['err'],
  editPermission: ['users'],
  editAttributeRequest: [],
  editAttributeSuccess: ['data'],
  editAttributeError: ['err'],
  editAttribute: ['users'],
});

/** --------------------------------
 * Variable declarations
 * --------------------------------- */

const INITIAL_STATE = Immutable({
  data: [],
  error: false,
  loading: false,
  request: {},
  count: 0,
  sort: { name: { value: 'name', order: 'asc' } },
  expandedRow: null,
});

/* Reducers Table */

export const init = state => state.merge({ ...INITIAL_STATE });

export const request = state => state.merge({ loading: true });

export const success = (state, { data }) =>
  state.merge({ data, error: false, loading: false });

export const error = state => state.merge({ loading: false, error: true });

export const sortBy = (state, { data, sort }) =>
  state.merge({ data, sort, expandedRow: null });

export const expand = (state, { expandedRow }) => state.merge({ expandedRow });

export const initTable = state =>
  state.merge({ sort: INITIAL_STATE.sort, expandedRow: null });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.RE_SEND_EMAIL_REQUEST]: request,
  [Types.RE_SEND_EMAIL_SUCCESS]: success,
  [Types.RE_SEND_EMAIL_ERROR]: error,
  [Types.DELETE_USERS_REQUEST]: request,
  [Types.DELETE_USERS_SUCCESS]: success,
  [Types.DELETE_USERS_ERROR]: error,
  [Types.USER_REGISTER_REQUEST]: request,
  [Types.USER_REGISTER_SUCCESS]: success,
  [Types.USER_REGISTER_ERROR]: error,
  [Types.GET_USERS_REQUEST]: request,
  [Types.GET_USERS_SUCCESS]: success,
  [Types.GET_USERS_ERROR]: error,
  [Types.GET_USERS_INIT]: init,
  [Types.GET_USERS_SORT_BY]: sortBy,
  [Types.GET_USERS_EXPAND]: expand,
  [Types.GET_USERS_INIT_TABLE]: initTable,
  [Types.EDIT_PERMISSION_REQUEST]: request,
  [Types.EDIT_PERMISSION_SUCCESS]: success,
  [Types.EDIT_PERMISSION_ERROR]: error,
  [Types.EDIT_ATTRIBUTE_REQUEST]: request,
  [Types.EDIT_ATTRIBUTE_SUCCESS]: success,
  [Types.EDIT_ATTRIBUTE_ERROR]: error,
});
