import { combineReducers } from 'redux';

import tenant from './tenant';
import title from './title';
import dashboard from './dashboard';
import tenant_config from './tenant_config';
import anomaly_management from './anomaly_management';
import metabase_callback from './metabase_callback';
import user_register from './user_register';
import change_icon from './change_icon';
import { reducer as toastr } from 'react-redux-toastr';

const rootReducer = combineReducers({
  tenant,
  toastr,
  title,
  dashboard,
  tenant_config,
  anomaly_management,
  metabase_callback,
  user_register,
  change_icon,
});

export default rootReducer;
