import React, { Component } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import { Separator, SubmitButton, LoadingContainer } from "./styles";
import LdapGroupSelect from "./LdapGroupSelect";
import { login } from "../../store/sagas/login";
import { LDAP_USERNAME, LDAP_PASSWORD } from "../../constants";

class LdapGroupDialog extends Component {
  state = {
    group: null,
    isLoading: false,
  };

  onSubmit = async () => {
    const username = localStorage.getItem(LDAP_USERNAME);
    const password = localStorage.getItem(LDAP_PASSWORD);

    this.setState({ isLoading: true }, async () => {
      await login(username, password, this.state.group);
      window.history.pushState({}, null, "/");
      window.location.reload();
    });
  };

  onSelect = (group) => {
    this.setState({ group });
  };

  render = () => {
    return (
      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        onClose={() => {}}
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>Selecione um Grupo de Acesso</b>
        </DialogTitle>
        <DialogContent>
          <LdapGroupSelect onSelect={this.onSelect} />
        </DialogContent>
        <Separator />
        <DialogActions>
          {!this.state.isLoading ? (
            <SubmitButton
              variant="outlined"
              component="span"
              onClick={this.onSubmit}
              disabled={!this.state.group}
              color="primary"
              autoFocus
            >
              Selecionar grupo
            </SubmitButton>
          ) : null}
        </DialogActions>

        {this.state.isLoading ? (
          <LoadingContainer>
            <div>
              <CircularProgress color="primary" size={20} />
            </div>
          </LoadingContainer>
        ) : null}
      </Dialog>
    );
  };
}

export default LdapGroupDialog;
