import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { LDAP_GROUPS } from "../../constants";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

export default function LdapGroupSelect(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState(false);
  const groups = JSON.parse(localStorage.getItem(LDAP_GROUPS));

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setError(false);
    props.onSelect(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    props.onSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl
        component="fieldset"
        error={error}
        className={classes.formControl}
      >
        <RadioGroup
          aria-label="select"
          name="select"
          value={value}
          onChange={handleRadioChange}
        >
          {groups.map((item, i) => (
            <FormControlLabel
              key={i}
              value={item}
              control={<Radio />}
              label={item}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </form>
  );
}
