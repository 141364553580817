import { Creators } from '../ducks/anomaly_management';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import api from '../../services/api';

export function* getAnomalies({ query }) {
  try {
    yield put(Creators.anomalyManagementRequest());
    const response = yield call(api.get, 'feedback', { params: query });

    if (response.data) yield put(Creators.anomalyManagementSuccess(response.data));
    else yield put(Creators.anomalyManagementError({ error: true }));
  } catch (error) {
    console.error(error);
    yield put(Creators.anomalyManagementError({ error }));
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro',
        message: 'Falha ao buscar anomalias. Por favor, entre em contato com o suporte.',
      })
    );
  }
}

export function* postAnomalies({ request }) {
  try {
    yield put(Creators.anomalyManagementRequest());
    let response = yield call(api.post, 'feedback', request);

    if (response.data && response.data.request_status) {
      response = yield call(api.get, 'feedback', { params: response.data.query });
      yield put(
        toastrActions.add({
          type: 'success',
          title: 'Sucesso',
          message: 'Verificação enviada!',
        })
      );
      if (response.data) yield put(Creators.anomalyManagementSuccess(response.data));
      else yield put(Creators.anomalyManagementError({ error: true }));
    } else {
      yield put(Creators.anomalyManagementError({ error: true }));
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro',
          message: 'Erro ao alterar status da anomalia!',
        })
      );
    }
  } catch (error) {
    console.error(error);
    yield put(Creators.anomalyManagementError({ error }));
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro',
        message: 'Erro interno! Por favor, entre em contato com o suporte.',
      })
    );
  }
}
