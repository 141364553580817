import React, { forwardRef } from 'react';
import TextField from '@material-ui/core/TextField';

const phoneInput = (props, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      variant="outlined"
      required={false}
      label="Telefone"
      placeholder="Telefone"
    />
  );
};

export default forwardRef(phoneInput);
