import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import store from './store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18n, Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import i18nData from './i18n/pt.json';
import ReduxToastr from 'react-redux-toastr';
import 'react-vertical-timeline-component/style.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-pro-sidebar/dist/css/styles.css';
import 'moment/locale/pt.js';
import moment from 'moment';
import { SnackbarProvider } from 'notistack';
import { interceptAuthEvents } from './utils/cognito';

require('dotenv').config();

const AMPLIFY_CFG = {
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USERPOOLID,
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    userPoolWebClientId: process.env.REACT_APP_APPCLIENTID,
  },
};

Amplify.configure(AMPLIFY_CFG);
I18n.setLanguage('pt');
I18n.putVocabularies({ pt: i18nData });
moment.locale('pt');

interceptAuthEvents();

const Toastr = () => (
  <ReduxToastr
    timeOut={4000}
    newestOnTop={false}
    preventDuplicates
    closeOnToastrClick={true}
    position="top-right"
    getState={state => state.toastr} // This is the default
    transitionIn="fadeIn"
    transitionOut="fadeOut"
    progressBar
  />
);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Toastr />
      <SnackbarProvider>
        <Authenticator.Provider>
          <App />
        </Authenticator.Provider>
      </SnackbarProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);
export { store };
