import api from '../../services/api';
import { Creators } from '../ducks/change_icon';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { setCustomFavicon } from '../../utils';

export function* postChangeIcon({ file }) {
  try {
    yield put(Creators.changeIconRequest());
    const response = yield call(api.post, '/change_icon', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
        'Access-Control-Allow-Origin': '*',
      },
    });

    yield put(Creators.changeIconSuccess(response.data.msg));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Logo editada com sucesso',
      })
    );
    setCustomFavicon(null);
  } catch (err) {
    yield put(Creators.changeIconError({ err }));
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro!',
        message: 'Falha ao editar logo',
      })
    );
  }
}
