import React from 'react';

// .../settings/users
import { USERS_SETTINGS } from '../constants/routes';
import UsersSettings from '../components/Settings/UsersSettings';
import UsersIcon from 'react-feather/dist/icons/users';
import { getTheme } from './theme';

const theme = getTheme(null, null);

export const settingsOptions = {
  users: {
    name: 'Usuários',
    icon: <UsersIcon color={theme.icon_color_sidemenu} />,
    linkTo: USERS_SETTINGS,
    component: <UsersSettings />,
  },
};
