import styled from 'styled-components';
import { getTheme } from '../../utils/theme';

const customTheme = getTheme(null, null);

export const Container = styled.div`
  padding: 0;
  height: 100%; 
  background-color: white;
`;

export const CallbackMsgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40%;
  flex-direction: column;
`

export const MsgText = styled.div`
  padding-top: 2vh;
  font-size: 20px;
  color: ${customTheme.text_color}
`;