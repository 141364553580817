import React from 'react';
import { login } from '../../store/sagas/login';
import { getTenantByHost } from '../../utils';
import { BUCKET_PATH } from '../../constants';
import {
  Authenticator,
  View,
  Image,
  Text,
  Heading,
  useTheme,
  Button,
  useAuthenticator,
  ThemeProvider
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import '@fontsource/inter/variable.css';
import { getTheme } from '../../utils/theme';

const getAmplifyTheme = (tokens, tenant) => {
  const themProps = getTheme(tokens, tenant);

  const amplifyTheme = {
    name: 'Custom',
    tokens: {
      components: {
        button: {
          primary: {
            backgroundColor: themProps?.login?.color || tokens.colors.orange[60],
            '&:hover': {
              backgroundColor: themProps?.login?.color_hover || tokens.colors.orange[80],
            },
          },
          link: {
            color: themProps?.login?.color || tokens.colors.orange[60],
            '&:hover': {
              color: themProps?.login?.color_hover || tokens.colors.orange[80],
            },
          },
        },
      },
    },
  };

  return amplifyTheme;
}

const components = {
  Header() {
    const { tokens } = useTheme();
    const tenant = getTenantByHost();
    const theme = getTheme(null, tenant);
    const imageSrc = `${BUCKET_PATH}login/${tenant}.png`;

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image maxWidth={theme?.login?.logo_width || '30%'} alt="Authentication logo" src={imageSrc} />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();
    const tenant = getTenantByHost().toUpperCase();
    const capitalizedTenant = tenant.charAt(0).toUpperCase() + tenant.slice(1).toLowerCase();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral['80']}>&copy; {capitalizedTenant} | Oncase</Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Logue na sua conta
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={`${tokens.space.xs} 0`}>
          <Button fontWeight="normal" onClick={toResetPassword} size="small" variation="link">
            Recuperar senha
          </Button>
        </View>
      );
    },
  },
  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Crie uma nova conta
        </Heading>
      );
    },
    Footer() {
      const { tokens } = useTheme();
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center" padding={`${tokens.space.xs} 0`}>
          <Button fontWeight="normal" onClick={toSignIn} size="small" variation="link">
            Voltar à tela inicial
          </Button>
        </View>
      );
    },
  },
  ResetPassword: {
    Header() {
      return <Heading level={3}>Recuperação de senha</Heading>;
    },
    Footer() {
      return <Text>Se continuar tendo problemas, contate um administrador</Text>;
    },
  },
};

const services = {
  async handleSignIn(formData) {
    const { username, password } = formData;

    return login(username, password);
  },
};

const formFields = {
  signIn: {
    username: {
      labelHidden: true,
      placeholder: 'Usuário ou email',
      isRequired: true,
    },
    password: {
      labelHidden: true,
      placeholder: 'Senha',
      isRequired: true,
    },
  },
  resetPassword: {
    username: {
      labelHidden: false,
      label: 'Digite o seu usuário:',
      placeholder: 'Usuário',
    },
  },
};

const Auth = () => {
  const { tokens } = useTheme();
  const tenant = getTenantByHost();
  const amplifyTheme = getAmplifyTheme(tokens, tenant);

  return (
    <ThemeProvider theme={amplifyTheme}>
      <Authenticator
        services={services}
        formFields={formFields}
        components={components}
        loginMechanisms={['username', 'email']}
        hideSignUp
      />
    </ThemeProvider>
  )
};


export default Auth;