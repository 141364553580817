import { compose } from 'react-recompose/dist/Recompose.cjs';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import EmptyDash from '../EmptyDash';
import Metabase from '../Metabase';
import { Creators as TitleCreators } from '../../store/ducks/title';

class Dashboard extends Component {

  componentDidMount() {
    this.props.setTitle('Dashboard');
  }

  render = () => {
    const { id } = this.props.match.params;
    const { data } = this.props.dashboard;
    let dashboard = null;

    if (data && data.dashboards_by_id && data.dashboards_by_id[id]) {
      dashboard = data.dashboards_by_id[id];
    } else {
      dashboard = { dashboard_id: id };
    }

    return (
      <>
        {!id ? <EmptyDash /> : null}
        {id ? <Metabase dashboard={dashboard} /> : null}
      </>
    )
  }
}

const mapStateToProps = ({ dashboard }) => ({ dashboard });

export default compose(
  connect(mapStateToProps, { ...TitleCreators })
)(Dashboard);