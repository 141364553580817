import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DASHBOARD } from '../../constants/routes';
import { Redirect } from 'react-router';
import { compose } from 'react-recompose/dist/Recompose.cjs';
import { MsgContainer, EmptyText } from './styles';
import { Creators as TitleCreators } from '../../store/ducks/title';
import { CircularProgress } from '@material-ui/core';

class EmptyDash extends Component {
  componentDidMount() {
    this.props.setTitle('Dashboard');
  }

  renderLoading = () => {
    const { loading } = this.props.dashboard;

    if (loading) {
      return <CircularProgress size={36} />;
    }

    return null;
  };

  getEmptyDashMsg = () => {
    const { loading, data } = this.props.dashboard;

    if ((!data.dashboards || !data.dashboards.length) && !loading) {
      return <EmptyText>Por favor, solicite ao administrador para cadastrar um dashboard.</EmptyText>;
    }

    return null;
  };

  render() {
    const { data } = this.props.dashboard;

    if (data.dashboards && data.dashboards.length) {
      return <Redirect to={`${DASHBOARD}/${data.dashboards[0].dashboard_id}`} />;
    }

    return (
      <MsgContainer>
        {this.renderLoading()}
        {this.getEmptyDashMsg()}
      </MsgContainer>
    );
  }
}

const mapStateToProps = ({ dashboard }) => ({ dashboard });

export default compose(connect(mapStateToProps, { ...TitleCreators }))(EmptyDash);
