import React, { Component } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'react-recompose/dist/Recompose.cjs';
import Routes from '../../routes';
import LeftDrawer from '../LeftDrawer/LeftDrawer';
import styles from './App.styles';
import theme from './theme';
import { Creators as TenantCreators } from '../../store/ducks/tenant';
import { Creators as DashboardCreators } from '../../store/ducks/dashboard';
import { mixpanelTrackAuthWorkflow } from '../../utils/mixpanel';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { withSnackbar } from 'notistack';
import { getTenantByHost, setCustomFavicon, withAuthenticator } from '../../utils';
import { LDAP_IS_MUST_SELECT_GROUP } from '../../constants';
import Auth from './Auth';
import { getTheme } from '../../utils/theme';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openSideMenu: false,
    };
  }

  componentDidMount() {
    const tenant = getTenantByHost();
    const themeProps = getTheme(null, tenant);
    const projectName = themeProps?.project_name || 'Oktopus';

    window.document.title = `${projectName} - Login`;
    setCustomFavicon(tenant);
  }

  componentDidUpdate() {
    if (localStorage.getItem('awsAuthStatus') === 'authenticated') {
      setCustomFavicon(null);

      if (localStorage.getItem(LDAP_IS_MUST_SELECT_GROUP) !== 'true') {
        this.props.getDashboards();
      }

      this.props.setTenant({});
      mixpanelTrackAuthWorkflow();
    }
  }

  handleChangeSideMenu(e) {
    if (e) {
      let open = this.state.openSideMenu;
      this.setState({ openSideMenu: !open });
    } else {
      this.setState({ openSideMenu: false });
    }
  }

  handleClickOut = e => {
    if (this.listSubNode != null) {
      if (this.listSubNode.contains(e.target)) {
        return;
      }

      this.setState({ openSideMenu: false });
    }
  };

  render() {
    const { classes, location } = this.props;

    return (
      <>
        {localStorage.getItem('awsAuthStatus') !== 'authenticated' ? (
          <Auth />
        ) : (
          <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
              <div className={classes.root}>
                <div className={classNames(classes.appFrame, classes.hiddenDrawerBD)}>
                  <LeftDrawer />
                  <div className={classes.contentBody}>
                    <Routes location={location} />
                  </div>
                </div>
              </div>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ title }) => ({ title });

export default compose(
  withAuthenticator,
  withRouter,
  withSnackbar,
  withStyles(styles),
  connect(mapStateToProps, { ...TenantCreators, ...DashboardCreators })
)(App);