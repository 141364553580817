export const ENEL = 'enel';
export const ENELGO = 'enelgo';
export const ENELRJ = 'enelrj';
export const OKTOPUS = 'oktopus';
export const CESAN = 'cesan';

export const getTenant = (overrideTenant) => {
  const tenant = localStorage.getItem('tenant') || overrideTenant;

  const tenants = {
    [ENELGO]: {
      name: 'Enel GO',
      company: ENEL,

    },
    [ENELRJ]: {
      name: 'Enel RJ',
      company: ENEL,
    },
    [OKTOPUS]: {
      name: 'Oktopus',
      company: OKTOPUS,
    },
    [CESAN]: {
      name: 'Cesan',
      company: CESAN,
    }
  }

  return tenants[tenant] || tenants[OKTOPUS];
}