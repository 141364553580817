import styled from 'styled-components';

export const FormFieldDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 3%;
  justify-content: center;
`;

export const AccountStatusCellDiv = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 7.5%;
`;
