import React, { Component } from 'react';
import { compose } from 'react-recompose/dist/Recompose.cjs';
import { connect } from 'react-redux';
import { Creators as UserRegisterCreators } from '../../../store/ducks/user_register';
import { FormFieldDiv, AccountStatusCellDiv } from './styles';
import muiPhoneInput from './muiPhoneInput';
import { alpha } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Grid,
  Modal,
  TextField,
  Select,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  CircularProgress,
  Popover,
  List,
  ListItem,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Close as CloseIcon,
  SendOutlined as SendIcon,
  PersonAddOutlined as PersonAddOutlinedIcon,
  RemoveCircleOutlineOutlined as RemoveOutlinedIcon,
  LockTwoTone as LockTwoToneIcon,
  LockOpenTwoTone as LockOpenTwoToneIcon,
  EmailOutlined as EmailIcon,
  Markunread as EmailUnreadedIcon,
  EditOutlined as EditOutlinedIcon,
  MoreHoriz as DotsIcon,
  CancelOutlined as CancelOutlinedIcon,
  Check as CheckIcon,
} from '@material-ui/icons';
import 'react-phone-number-input/style.css';
import { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';
import br from 'react-phone-number-input/locale/pt-BR';
import { tableHeadCells, errorStatusInitialState, emailRegex } from '../utils/users';
import { divideArray, noBreakLineSpace, checkIfObjectIsEmpty } from '../../../utils';
import { toastr } from 'react-redux-toastr';
import '../Settings.styles.css';

class UsersSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'role',
      selected: [],
      page: 0,
      rowsPerPage: 5,
      createNewUser: false,
      username: '',
      email: '',
      name: '',
      phone_number: '',
      errorStatus: errorStatusInitialState,
      errorEditStatus: errorStatusInitialState,
      role: 'Normal',
      showTableActionsComponent: {},
      editedPhoneNumber: '',
      editedEmail: '',
      editedName: '',
      userToEdit: null,
    };

    this.headCells = tableHeadCells;

    this.formFieldRules = {
      username: true,
      email: true,
      name: false,
      phone_number: false,
    };

    this.tableActions = [
      {
        name: boolExpr => 'Editar permissão',
        tootltipTitle: boolExpr => (boolExpr ? 'Tornar administrador' : 'Retirar cargo de administrador'),
        icon: boolExpr => (boolExpr ? <PersonAddOutlinedIcon /> : <RemoveOutlinedIcon />),
        property: 'role',
        expr: role => role !== 'Admin',
        disabledParam: null,
        onClick: element => this.editPermission(element),
      },
      {
        name: boolExpr => 'Re-enviar código',
        tootltipTitle: boolExpr => 'Re-enviar código para recuperação de senha',
        icon: boolExpr => <SendIcon />,
        property: '',
        expr: null,
        disabledParam: {
          property: 'UserStatus',
          expr: element => element !== 'CONFIRMED',
        },
        onClick: element => this.resendEmail(element),
      },
      {
        name: boolExpr => (boolExpr ? 'Cancelar edição' : 'Editar informações'),
        tootltipTitle: boolExpr => (boolExpr ? 'Cancelar edição de informações' : 'Editar informações do usuário'),
        icon: boolExpr => (boolExpr ? <CancelOutlinedIcon /> : <EditOutlinedIcon />),
        property: 'username',
        expr: username => this.state.userToEdit && username === this.state.userToEdit.username,
        disabledParam: {
          property: 'username',
          expr: username => this.state.userToEdit && username !== this.state.userToEdit.username,
        },
        onClick: element => this.handleChangeEditMode(element),
      },
    ];

    this.accountStatus = [
      {
        property: 'email_verified',
        expr: x => x === 'true',
        tootltipTitle: boolExpr => (boolExpr ? 'Email verificado' : 'Email não-verificado'),
        icon: boolExpr =>
          boolExpr ? <EmailIcon style={{ color: '#4E944F' }} /> : <EmailUnreadedIcon color="error" />,
      },
      {
        property: 'UserStatus',
        expr: x => x === 'CONFIRMED',
        tootltipTitle: boolExpr => (boolExpr ? 'Senha renovada' : 'Necessita renovar senha'),

        icon: boolExpr =>
          boolExpr ? <LockTwoToneIcon style={{ color: '#4E944F' }} /> : <LockOpenTwoToneIcon color="error" />,
      },
    ];

    this.editableCells = {
      name: {
        editComponent: name => (
          <TextField
            error={this.state.errorEditStatus.name.error}
            variant="outlined"
            label="Nome"
            placeholder="Nome"
            helperText={!this.state.errorEditStatus.name.error ? '' : this.state.errorEditStatus.name.errMsg}
            onChange={event => this.handleChangeFormField(event, 'editedName')}
            type="name"
            name="name_edit_cell"
            size="small"
            value={name}
            style={{ backgroundColor: 'rgba(99, 86, 102, 0.05)' }}
          />
        ),
      },
      email: {
        editComponent: email => (
          <TextField
            error={this.state.errorEditStatus.email.error}
            required
            variant="outlined"
            label="Email"
            placeholder="Email"
            helperText={!this.state.errorEditStatus.email.error ? '' : this.state.errorEditStatus.email.errMsg}
            onChange={event => this.handleChangeFormField(event, 'editedEmail')}
            type="email"
            name="email_edit_cell"
            size="small"
            value={email}
            style={{ backgroundColor: 'rgba(99, 86, 102, 0.05)' }}
          />
        ),
      },
      phone_number: {
        editComponent: phone_number => (
          <PhoneInput
            error={this.state.errorEditStatus.phone_number.error}
            helperText={
              !this.state.errorEditStatus.phone_number.error ? '' : this.state.errorEditStatus.phone_number.errMsg
            }
            country="BR"
            value={phone_number}
            labels={br}
            onChange={value =>
              this.setState({
                editedPhoneNumber: value ? value.trim() : '',
                errorEditStatus: errorStatusInitialState,
              })
            }
            inputComponent={muiPhoneInput}
            name="phone_number_edit"
            size="small"
            style={{ backgroundColor: 'rgba(99, 86, 102, 0.05)' }}
          />
        ),
      },
    };
  }

  resetFormState = () => {
    this.setState(prevState => ({
      ...prevState,
      createNewUser: false,
      username: '',
      email: '',
      name: '',
      phone_number: '',
      errorStatus: errorStatusInitialState,
    }));
  };

  componentDidMount() {
    this.currentUsername = localStorage.getItem('tenantUsername');
    this.props.getUsers({});
  }

  descendingComparator = (a, b, orderBy) => {
    let comparisonA = a[orderBy] ? a[orderBy].replace(/\s/g, '') : '';
    let comparisonB = b[orderBy] ? b[orderBy].replace(/\s/g, '') : '';

    if (orderBy === 'UserLastModifiedDate') {
      comparisonA = new Date(a['UserLastModifiedDateToCompare']);
      comparisonB = new Date(b['UserLastModifiedDateToCompare']);
    } else if (orderBy === 'phone') {
      comparisonA = formatPhoneNumberIntl(a['phone_number']);
      comparisonB = formatPhoneNumberIntl(b['phone_number']);
    }

    if (comparisonB < comparisonA) return -1;
    if (comparisonB > comparisonA) return 1;
    return 0;
  };

  getComparator = (order, orderBy) =>
    order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);

  getSortedArray = (array, comparator) => {
    if (array && array.length > 1) {
      const stabilizedThis = [];
      array.forEach((el, index) => stabilizedThis.push([el, index]));

      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        return order ? order : a[1] - b[1];
      });

      return stabilizedThis?.map(el => el[0]);
    }

    return array;
  };

  EnhancedTableHead = props => {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'Selecionar todos',
              }}
            />
          </TableCell>
          {this.headCells?.map(headCell =>
            headCell.sortable ? (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? <Box component="span" /> : null}
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
              >
                {headCell.label}
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
    );
  };

  EnhancedTableToolbar = props => {
    const { numSelected } = props;
    const { userToEdit } = this.state;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: theme => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        <Grid container alignItems="center" spacing={1} justifyContent="flex-start">
          {numSelected > 0 ? (
            <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="p">
              {numSelected} {numSelected > 1 ? 'selecionados' : 'selecionado'}
            </Typography>
          ) : (
            <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="h2">
              Usuários cadastrados
            </Typography>
          )}

          {numSelected > 0 ? (
            <Tooltip title={numSelected > 1 ? 'Deletar usuários' : 'Deletar usuário'}>
              <IconButton onClick={this.deleteUser}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </Grid>
        <Grid container spacing={2} justifyContent="flex-end">
          {userToEdit && (
            <Grid item>
              <Button
                onClick={this.sendUserEdits}
                endIcon={<CheckIcon />}
                variant="contained"
                className="default-button"
                disabled={!this.checkEditFields()}
              >
                Confirmar mudanças
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              onClick={this.handleOpenModal}
              endIcon={<AddIcon />}
              variant="contained"
              className="default-button"
              disabled={userToEdit}
            >
              Criar novo usuário
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  handleChangeRole = event => {
    this.setState({ role: event.target.value });
  };

  handleSelectAllClick = event => {
    const { data } = this.props.user_register;
    if (event.target.checked) {
      const newSelecteds = data?.map(n => n.username);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClickCheckbox = (event, username) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(username);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, username);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected?.slice(0, selectedIndex), selected?.slice(selectedIndex + 1));
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  isSelected = property => this.state.selected.indexOf(property) !== -1;

  handleOpenModal = _ => {
    this.setState({ createNewUser: true });
  };

  handleCloseModal = _ => {
    this.setState({ createNewUser: false });
    this.resetFormState();
  };

  handleChangeFormField = (event, name) => {
    this.setState({
      [name]: event.target.value.trim(),
      errorStatus: errorStatusInitialState,
      errorEditStatus: errorStatusInitialState,
    });
  };

  handleChangePhoneNumber = value => {
    this.setState({
      phone_number: value ? value.trim() : '',
      errorStatus: errorStatusInitialState,
      errorEditStatus: errorStatusInitialState,
    });
  };

  handleOpenActionsPopover = (event, element) => {
    const { showTableActionsComponent } = this.state;
    const newShowTableActionsComponent = { ...showTableActionsComponent };
    newShowTableActionsComponent[element.username] = event.currentTarget;

    this.setState({
      showTableActionsComponent: newShowTableActionsComponent,
    });
  };

  handleCloseActionsPopover = element => {
    const { showTableActionsComponent } = this.state;
    const newShowTableActionsComponent = { ...showTableActionsComponent };
    newShowTableActionsComponent[element.username] = null;

    this.setState({
      showTableActionsComponent: newShowTableActionsComponent,
    });
  };

  handleChangeEditMode = element => {
    const { userToEdit } = this.state;

    if (!userToEdit || userToEdit.username === element.username) {
      this.setState({
        editedEmail: element ? element.email : '',
        editedPhoneNumber: element ? element.phone_number : '',
        editedName: element ? element.name : '',
        errorEditStatus: errorStatusInitialState,
        userToEdit: !userToEdit ? element : null,
      });
    }
  };

  renderFormFieldsValues = _ => {
    const { phone_number, role } = this.state;

    return [
      {
        name_id: 'name',
        label: 'Nome',
        placeholder: 'Nome',
        helperText: 'Nome do usuário',
        type: 'text',
        InputProps: {},
        value: '',
        variant: 'outlined',
        required: this.formFieldRules.name,
      },
      {
        name_id: 'email',
        label: 'Email',
        placeholder: 'Email',
        helperText: 'Email do usuário',
        type: 'text',
        InputProps: {},
        value: '',
        variant: 'outlined',
        required: this.formFieldRules.email,
      },
      {
        name_id: 'username',
        label: 'Usuário',
        placeholder: 'Usuário',
        helperText: 'Nome de usuário ou email de acesso',
        type: 'text',
        InputProps: {},
        value: '',
        variant: 'outlined',
        required: this.formFieldRules.username,
      },
      {
        name_id: 'phone_number',
        label: '',
        placeholder: '',
        helperText: 'Telefone do usuário',
        type: 'text',
        InputProps: {},
        value: phone_number,
        variant: 'outlined',
        required: this.formFieldRules.phone_number,
      },
      {
        name_id: 'role',
        label: 'Função',
        placeholder: 'Permissão',
        helperText: '',
        type: 'text',
        InputProps: {},
        value: role,
        variant: 'outlined',
        required: false,
      },
    ];
  };

  renderTableCells = (element, isItemSelected, labelId) => {
    const { showTableActionsComponent, userToEdit, editedEmail, editedPhoneNumber, editedName } = this.state;

    if (checkIfObjectIsEmpty(showTableActionsComponent) || !(element.username in showTableActionsComponent)) {
      this.setState(prevState => ({
        showTableActionsComponent: { ...prevState.showTableActionsComponent, [element.username]: null },
      }));
    }

    return (
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={element.username}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox" onClick={event => this.handleClickCheckbox(event, element.username)}>
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelId,
            }}
          />
        </TableCell>
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          style={{ fontWeight: element.name ? 'Normal' : 'Bold', color: element.name ? '' : '#F34F24' }}
        >
          {!('name' in this.editableCells && userToEdit && userToEdit.username === element.username)
            ? element.name
              ? noBreakLineSpace(element.name)
              : 'N/A'
            : this.editableCells.name.editComponent(editedName)}
        </TableCell>
        <TableCell>
          {!('username' in this.editableCells && userToEdit && userToEdit.username === element.username)
            ? element.username
            : this.editableCells.username.editComponent(element.username)}
        </TableCell>
        <TableCell>
          {!('email' in this.editableCells && userToEdit && userToEdit.username === element.username)
            ? element.email
            : this.editableCells.email.editComponent(editedEmail)}
        </TableCell>
        <TableCell
          style={{
            fontWeight: element.phone_number ? 'Normal' : 'Bold',
            color: element.phone_number ? '' : '#F34F24',
          }}
        >
          {!('phone_number' in this.editableCells && userToEdit && userToEdit.username === element.username)
            ? element.phone_number
              ? noBreakLineSpace(formatPhoneNumberIntl(element.phone_number))
              : 'N/A'
            : this.editableCells.phone_number.editComponent(editedPhoneNumber)}
        </TableCell>
        <TableCell>
          {!(
            'UserLastModifiedDate' in this.editableCells &&
            userToEdit &&
            userToEdit.username === element.username
          )
            ? noBreakLineSpace(element.UserLastModifiedDate)
            : this.editableCells.UserLastModifiedDate.editComponent(element.UserLastModifiedDate)}
        </TableCell>
        <TableCell>
          {!('role' in this.editableCells && userToEdit && userToEdit.username === element.username)
            ? element.role
            : this.editableCells.role.editComponent(element.role)}
        </TableCell>
        <TableCell>
          <AccountStatusCellDiv>
            {this.accountStatus?.map((st, idx) => {
              const boolExpr = st.expr(element[st.property]);

              return (
                <Tooltip title={st.tootltipTitle(boolExpr)} key={`account_status_${idx}`}>
                  {st.icon(boolExpr)}
                </Tooltip>
              );
            })}
          </AccountStatusCellDiv>
        </TableCell>
        <TableCell>
          <Tooltip title="Ações">
            <IconButton size="small" onClick={event => this.handleOpenActionsPopover(event, element)}>
              <DotsIcon />
            </IconButton>
          </Tooltip>
          <Popover
            id={`showTableActionsComponent_${element.username}`}
            open={
              !checkIfObjectIsEmpty(showTableActionsComponent) &&
              element.username in showTableActionsComponent &&
              showTableActionsComponent[element.username]
            }
            onClose={_ => this.handleCloseActionsPopover(element)}
            anchorEl={
              !checkIfObjectIsEmpty(showTableActionsComponent) && element.username in showTableActionsComponent
                ? showTableActionsComponent[element.username]
                : null
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <List>
              {this.tableActions?.map((act, idx) => (
                <ListItem key={`${act.name}_${element.username}_${idx}`}>
                  <Tooltip
                    title={
                      act.tootltipTitle ? act.tootltipTitle(act.expr && act.expr(element[act.property])) : act.name
                    }
                  >
                    <Button
                      endIcon={act.icon(act.expr && act.expr(element[act.property]))}
                      onClick={_ => act.onClick(element)}
                      disabled={
                        act.disabledParam &&
                        act.disabledParam.property in element &&
                        act.disabledParam.expr(element[act.disabledParam.property])
                      }
                      size="small"
                    >
                      {act.name(act.expr && act.expr(element[act.property]))}
                    </Button>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </Popover>
        </TableCell>
      </TableRow>
    );
  };

  renderFormFields = ({ label, placeholder, helperText, name_id, type, InputProps, value, variant, required }) =>
    name_id === 'role' ? (
      <FormControl style={{ margin: '3%', width: 280 }} justifyContent="space-between">
        <InputLabel id="role">{placeholder}</InputLabel>
        <Select labelId="role" id="role" value={value} label={label} onChange={this.handleChangeRole}>
          <MenuItem value="Normal">Normal</MenuItem>
          <MenuItem value="Admin">Admin</MenuItem>
        </Select>
        <FormHelperText>Função do usuário</FormHelperText>
      </FormControl>
    ) : name_id !== 'phone_number' ? (
      <TextField
        error={this.state.errorStatus[name_id].error}
        variant={variant}
        required={required}
        label={label}
        placeholder={placeholder}
        helperText={!this.state.errorStatus[name_id].error ? helperText : this.state.errorStatus[name_id].errMsg}
        onChange={event => this.handleChangeFormField(event, name_id)}
        type={type}
        InputProps={InputProps}
        style={{ margin: '3%', width: 280 }}
        name={name_id}
      />
    ) : (
      <PhoneInput
        error={this.state.errorStatus.phone_number.error}
        helperText={
          !this.state.errorStatus.phone_number.error ? helperText : this.state.errorStatus.phone_number.errMsg
        }
        country="BR"
        rules={{ required }}
        value={value}
        labels={br}
        onChange={this.handleChangePhoneNumber}
        inputComponent={muiPhoneInput}
        name="phone_number"
        style={{ margin: '3%', width: 280 }}
      />
    );

  deleteUser = _ => {
    const { selected, userToEdit } = this.state;

    if (selected.includes(this.currentUsername)) {
      toastr.warning('Ação não permitida!', 'Não é possível deletar o próprio usuário.');
    } else {
      this.props.deleteUsers({ users: selected, action: 'delete_account_from_cognito' });
      this.setState({ selected: [], showTableActionsComponent: {} });
      if (userToEdit && selected.includes(userToEdit.username)) this.handleChangeEditMode(userToEdit);
    }
  };

  validateEmail = email => email.toLowerCase().match(emailRegex);

  signUp = _ => {
    const { username, email, name, phone_number, role, errorStatus } = this.state;

    const { addUser } = this.props;

    if (
      (this.formFieldRules.username && !username) ||
      (this.formFieldRules.email && !email) ||
      (this.formFieldRules.name && !name) ||
      (this.formFieldRules.phone_number && !phone_number)
    ) {
      toastr.warning('Atenção', 'É necessário preencher todos os campos marcados para efetuar o cadastro!');
    } else {
      const validates = {
        email: Boolean(this.validateEmail(email)),
        phone_number: !phone_number || isValidPhoneNumber(phone_number),
      };
      let checks = true;
      const newErrorStatus = { ...errorStatus };

      for (let fields in validates) {
        newErrorStatus[fields] = { ...newErrorStatus[fields], error: !validates[fields] };
        if (!validates[fields] && checks) checks = false;
      }
      this.setState({
        errorStatus: newErrorStatus,
      });

      if (checks) {
        const formatedPhoneNumber = formatPhoneNumberIntl(phone_number).replace(/\s/g, '');

        addUser({
          username,
          email,
          name,
          formatedPhoneNumber,
          role,
        });
      } else {
        toastr.error('Erro', 'Por favor, verifique os campos de preenchimento.');
      }
    }
  };

  resendEmail(element) {
    this.props.reSendEmail({
      users: [element.username],
      action: 're_send_email',
      client_id: process.env.REACT_APP_APPCLIENTID,
    });
    this.setState({ showTableActionsComponent: {} });
  }

  editPermission(element) {
    if (this.currentUsername === element.username) {
      toastr.warning('Ação não permitida!', 'Não é possível editar a permissão do próprio usuário.');
    } else {
      if (element.role === 'Admin') {
        this.props.editPermission({ users: [element.username], action: 'delete_account', group: 'admin' });
      } else {
        this.props.editPermission({ users: [element.username], action: 'make_admin' });
      }
      this.setState({ showTableActionsComponent: {} });
    }
  }

  sendUserEdits = _ => {
    const { editedName, editedEmail, editedPhoneNumber, errorEditStatus, userToEdit } = this.state;
    const validates = {
      email: editedEmail && Boolean(this.validateEmail(editedEmail)),
      phone_number: !editedPhoneNumber || isValidPhoneNumber(editedPhoneNumber),
    };
    let checks = true;
    const newErrorEditStatus = { ...errorEditStatus };

    for (let fields in validates) {
      newErrorEditStatus[fields] = { ...newErrorEditStatus[fields], error: !validates[fields] };
      if (!validates[fields]) checks = false;
    }
    this.setState({
      errorEditStatus: newErrorEditStatus,
    });

    if (checks) {
      this.props.editAttribute({
        users: [userToEdit.username],
        action: 'change_attributes',
        new_name: editedName,
        new_email: editedEmail,
        new_phone_number: editedPhoneNumber,
      });
      this.handleChangeEditMode(userToEdit);
    }
  };

  checkEditFields = _ => this.state.editedEmail;

  render() {
    const { order, orderBy, selected, page, rowsPerPage, createNewUser } = this.state;

    const { loading, data } = this.props.user_register;

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

    return (
      <Box sx={{ width: '100%', padding: '1.5vw' }}>
        {loading ? (
          <CircularProgress size={60} style={{ position: 'absolute', top: '20%', left: '58%' }} />
        ) : (
          <>
            <Paper sx={{ width: '100%', mb: 2 }}>
              {this.EnhancedTableToolbar({ numSelected: selected?.length })}
              <TableContainer>
                <Table aria-labelledby="tableTitle" size="medium">
                  {this.EnhancedTableHead({
                    numSelected: selected?.length,
                    order: order,
                    orderBy: orderBy,
                    onSelectAllClick: this.handleSelectAllClick,
                    onRequestSort: this.handleRequestSort,
                    rowCount: data?.length,
                  })}
                  <TableBody>
                    {this.getSortedArray(data, this.getComparator(order, orderBy))
                      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      ?.map((row, index) =>
                        this.renderTableCells(
                          row,
                          this.isSelected(row.username),
                          `enhanced-table-checkbox-${index}`
                        )
                      )}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="footer"
                count={data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                labelRowsPerPage="Usuários por página:"
              />
            </Paper>
          </>
        )}
        <Modal open={createNewUser} onClose={this.handleCloseModal} aria-labelledby="create-user">
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              minWidth: 800,
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              borderRadius: '5px',
              boxShadow: 24,
              p: 1,
            }}
          >
            <Grid container item justifyContent="flex-end">
              <Tooltip title="Fechar">
                <IconButton onClick={this.handleCloseModal}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid container spacing={3} direction="column" alignItems="center" justifyContent="center">
              <Grid container item justifyContent="center">
                <Typography variant="h3" component="h3">
                  Cadastrar&nbsp;novo&nbsp;usuário
                </Typography>
              </Grid>
              <Paper elevation={4} square style={{ margin: '2%', padding: '3%', minWidth: '55%' }}>
                {divideArray(this.renderFormFieldsValues())?.map((field, index) => (
                  <Grid item key={index}>
                    <FormFieldDiv>{field?.map(value => this.renderFormFields(value))}</FormFieldDiv>
                  </Grid>
                ))}
                <Grid container item alignItems="center" justifyContent="space-between">
                  <Grid item>
                    <Typography variant="caption" component="label" color="error">
                      * Todos os campos marcados são de preenchimento obrigatório.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption" component="p">
                      Uma senha aleatória será criada para o novo usuário.
                    </Typography>
                    <Typography variant="caption" component="p">
                      Ele deverá checar o email cadastrado para mais instruções.
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              <Grid item style={{ paddingBottom: '3%' }}>
                <Button
                  disabled={loading}
                  variant="contained"
                  className="default-button default-button"
                  onClick={this.signUp}
                >
                  {loading ? 'Criando...' : 'Criar usuário'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
    );
  }
}

const mapStateToProps = ({ user_register }) => ({ user_register });

export default compose(connect(mapStateToProps, { ...UserRegisterCreators }))(UsersSettings);
