import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
  metabaseCallbackRequest: [],
  postMetabaseCallback: ['params'],
  metabaseCallbackSuccess: ['data'],
  metabaseCallbackError: ['err'],
});

/* Initial State */

export const INITIAL_STATE = Immutable({
  data: [],
  success: false,
  loading: false,
  error: false
});


/* Reducers */

export const request = state => state.merge({ success: false, loading: true });

export const success = (state, { data }) => state.merge({ data, error: false, loading: false, success: true });

export const error = state => state.merge({ loading: false, error: true, success: false });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.METABASE_CALLBACK_REQUEST]: request,
  [Types.METABASE_CALLBACK_SUCCESS]: success,
  [Types.METABASE_CALLBACK_ERROR]: error,
});
