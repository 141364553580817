import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { USER } from '../../constants';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
  setTenant: ['filter'],
  tenantSuccess: ['tenant', 'role', 'name']
});

/* Initial State */

export const INITIAL_STATE = Immutable({
  tenant: null,
  role: USER,
  name: null
});

/* Reducers */

export const success = (state, { tenant, role, name }) => state.merge({ tenant, role, name });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.TENANT_SUCCESS]: success
});
