import { compose } from 'react-recompose/dist/Recompose.cjs';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import * as jwt from 'jsonwebtoken';
import { Container } from './styles';
import { CircularProgress } from '@material-ui/core';
import { MsgContainer } from '../EmptyDash/styles';

class Metabase extends Component {

  getIframeProps = (dashboard) => {
    const theme = `theme=${dashboard.theme !== undefined ? dashboard.theme : 'night'}`;
    const titled = `titled=${dashboard.titled !== undefined ? dashboard.titled : 'true'}`;
    const bordered = `bordered=${dashboard.bordered !== undefined ? dashboard.bordered : 'false'}`;
    const refresh = `refresh=${dashboard.refresh !== undefined ? dashboard.refresh : '10'}`;

    return `#${theme}&${titled}&${bordered}&${refresh}`;
  }

  renderLoading = () => {
    const { loading } = this.props.dashboard;

    if (loading) {
      return <CircularProgress size={36} />;
    }

    return null;
  };

  render = () => {
    let params = {};
    let token = null;
    let iframeUrl = null;
    const metabaseSiteUrl = localStorage.getItem('REACT_APP_METABASE_SITE_URL') || process.env.REACT_APP_METABASE_SITE_URL;
    const metabaseSecretKey = localStorage.getItem('REACT_APP_METABASE_SECRET_KEY') || process.env.REACT_APP_METABASE_SECRET_KEY;
    const iFrameProps = this.getIframeProps(this.props.dashboard);

    if (!this.props.dashboard?.theme) {
      return (
        <MsgContainer>
          {this.renderLoading()}
        </MsgContainer>
      )
    }

    if (this.props.dashboard && this.props.dashboard.is_multi_tenant) {
      params.tenant = localStorage.getItem('tenant');
    }

    const payload = {
      resource: { dashboard: ~~this.props.dashboard.dashboard_id },
      params,
      exp: Math.round(Date.now() / 1000) + (10 * 60)
    };

    token = jwt.sign(payload, metabaseSecretKey);
    iframeUrl = metabaseSiteUrl + "/embed/dashboard/" + token + iFrameProps;

    return (
      <Container>
        <iframe
          title="metabase"
          src={iframeUrl}
          frameBorder="0"
          width="100%"
          height="100%"
          allowtransparency="true"
        ></iframe>
      </Container>
    )
  }
}

const mapStateToProps = ({ tenant }) => ({ tenant });

export default compose(
  connect(mapStateToProps, null)
)(Metabase);