import store from '../../store';
import api from '../../services/api';
import { Creators } from '../ducks/dashboard';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';


export function* getDashboards() {
  try {
    let response;
    const { data } = store.getState().dashboard;

    if (data.length) {
      return;
    }

    yield put(Creators.dashboardRequest());
    response = yield call(api.get, 'dashboard');

    if (response?.data?.services?.metabase_site_url) {
      localStorage.setItem('REACT_APP_METABASE_SITE_URL', response?.data?.services?.metabase_site_url);
    }

    if (response?.data?.services?.metabase_secret_key) {
      localStorage.setItem('REACT_APP_METABASE_SECRET_KEY', response?.data?.services?.metabase_secret_key);
    }

    yield put(Creators.dashboardSuccess(response.data));
  } catch (err) {
    yield put(Creators.dashboardError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao buscar dashboards'
    }));
  }
}