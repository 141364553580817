import mixpanel from 'mixpanel-browser';
import { Auth, Hub } from 'aws-amplify';
import { getUserData } from '.';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

const actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
};

const setIdentify = (user) => {
  actions.identify(user.email)

  actions.people.set({
    $name: user.name,
    $tenant: user.tenant,
    $email: user.email
  });
}

export async function mixpanelTrackAuthWorkflow() {
  let user = null;

  if (process.env.NODE_ENV !== 'production') return;

  user = await getUserData();

  if (user) setIdentify(user);

  console.info('[Mixpanel] Initialized!');

  Hub.listen("auth", ({ payload: { event, data } }) => {
    setIdentify(data);

    switch (event) {
      case 'signIn':
        actions.track('signIn');
        break;
      case 'signOut':
        actions.track('signOut');
        break;
      case 'signIn_failure':
        actions.track('signInFailure');
        break;
      default:
        break;
    }
  });

  Auth.currentAuthenticatedUser()
    .then(() => actions.track('initLoggedApp'))
    .catch(() => console.log("Not signed in"));
}

export const Mixpanel = actions;