import store from '../../store';
import { put } from 'redux-saga/effects';
import { Creators as TenantCreators } from '../ducks/tenant';
import { actions as toastrActions } from 'react-redux-toastr';
import { getUserData } from '../../utils';

export function* setTenant() {
  try {
    let userData = {};
    const { tenant } = store.getState().tenant;

    if (!tenant) {
      userData = yield getUserData();

      yield put(TenantCreators.tenantSuccess(userData.tenant, userData.role, userData.name));
    }
  } catch (err) {
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao habilitar tenant'
    }));
  }
}