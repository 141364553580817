import api from '../../services/api';
import { Creators } from '../ducks/user_register';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';

export function* addUser({ request }) {
  try {
    yield put(Creators.userRegisterRequest());
    const response = yield call(api.post, '/user_register', request);
    let err = false;

    if (response.data.error_cases.users_already_exists) {
      err = true;
      yield put(
        toastrActions.add({
          options: {
            timeOut: 0,
            showCloseButton: true,
            closeOnToastrClick: false,
          },
          type: 'error',
          title: 'Usuário já está cadastrado!',
          message:
            'Entre em contato com a Oncase para fazer o recadastramento da seguinte conta: ' + request.username,
        })
      );
    }

    if (response.data.error_cases.users_failure_send_email) {
      err = true;
      yield put(
        toastrActions.add({
          options: {
            timeOut: 0,
            showCloseButton: true,
            closeOnToastrClick: false,
          },
          type: 'error',
          title: 'Falha ao enviar email de confirmação ao usuário!',
          message:
            'Entre em contato com a Oncase para fazer o recadastramento da seguinte conta: ' + request.username,
        })
      );
    }

    if (!err) {
      yield put(
        toastrActions.add({
          type: 'success',
          title: 'Cadastrando usuário...',
          message:
            'Isso pode levar alguns minutos. Recarregue a página para verificar se o usuário já foi adicionado.',
        })
      );
    }

    const users = yield call(api.get, '/user_register');

    if (users.data.users && users.data.users.length) {
      yield put(Creators.getUsersSuccess(users.data.users));
    }
  } catch (err) {
    yield put(Creators.userRegisterError({ err }));
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro',
        message: 'Falha ao cadastrar o usuário',
      })
    );
  }
}

export function* getUsers({ request }) {
  try {
    yield put(Creators.getUsersRequest());
    const response = yield call(api.get, '/user_register', request);

    if (response.data.users && response.data.users.length) {
      yield put(Creators.getUsersSuccess(response.data.users));
    }
  } catch (err) {
    yield put(Creators.getUsersError({ err }));
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro',
        message: 'Falha ao listar contas de usuários.',
      })
    );
  }
}

export function* reSendEmail({ user }) {
  try {
    yield put(Creators.reSendEmailRequest());
    yield call(api.put, '/user_register', user);

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Senha reenviada.',
      })
    );

    const response = yield call(api.get, '/user_register', user);

    if (response.data.users && response.data.users.length) {
      yield put(Creators.getUsersSuccess(response.data.users));
    }
  } catch (err) {
    yield put(Creators.reSendEmailError({ err }));
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro',
        message: 'Falha ao reenviar o email para redefinição de senha.',
      })
    );
  }
}

export function* deleteUsers({ users }) {
  try {
    yield put(Creators.deleteUsersRequest());
    yield call(api.put, '/user_register', users);

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Usuário(s) deletado(s).',
      })
    );

    const response = yield call(api.get, '/user_register');

    if (response.data.users && response.data.users.length) {
      yield put(Creators.getUsersSuccess(response.data.users));
    }
  } catch (err) {
    yield put(Creators.deleteUsersError({ err }));
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro',
        message: 'Falha ao deletar o usuário.',
      })
    );
  }
}

export function* editPermission({ users }) {
  try {
    yield put(Creators.editPermissionRequest());
    yield call(api.put, '/user_register', users);

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Nível de permissão editado.',
      })
    );

    const response = yield call(api.get, '/user_register', users);

    if (response.data.users && response.data.users.length) {
      yield put(Creators.getUsersSuccess(response.data.users));
    }
  } catch (err) {
    yield put(Creators.editPermissionError({ err }));
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro',
        message: 'Falha ao editar nível permissão do usuário.',
      })
    );
  }
}

export function* editAttribute({ users }) {
  try {
    yield put(Creators.editAttributeRequest());
    yield call(api.put, '/user_register', users);

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Informações do usuário editadas.',
      })
    );

    const response = yield call(api.get, '/user_register', users);

    if (response.data.users && response.data.users.length) {
      yield put(Creators.getUsersSuccess(response.data.users));
    }
  } catch (err) {
    yield put(Creators.editAttributeError({ err }));
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro',
        message: 'Falha ao editar atributos do usuário.',
      })
    );
  }
}
