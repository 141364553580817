export const tableHeadCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
    sortable: true,
  },
  {
    id: 'username',
    numeric: false,
    disablePadding: false,
    label: 'Nome\xA0de\xA0usuário',
    sortable: true,
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    sortable: true,
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'Telefone',
    sortable: true,
  },
  {
    id: 'UserLastModifiedDate',
    numeric: false,
    disablePadding: false,
    label: 'Última\xA0modificação',
    sortable: true,
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Tipo',
    sortable: true,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status\xA0da\xA0conta',
    sortable: false,
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Ações',
    sortable: false,
  },
];

export const errorStatusInitialState = {
  name: { error: false, errMsg: '' },
  email: { error: false, errMsg: 'Email inválido' },
  phone_number: { error: false, errMsg: 'Telefone inválido' },
  username: { error: false, errMsg: 'Usuário inválido' },
};

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
