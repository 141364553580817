import { compose } from 'react-recompose/dist/Recompose.cjs';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { DASHBOARD, ROOT, LDAP_GROUP_SELECT } from '../../constants/routes';
import { Creators as TenantCreators } from '../../store/ducks/tenant';
import { LDAP_IS_MUST_SELECT_GROUP } from '../../constants';

class RouteRedirector extends Component {

  componentDidMount() {
    this.props.setTenant();
  }

  render() {
    const { tenant } = this.props.tenant;
    const ldapIsMustSelectGroup = localStorage.getItem(LDAP_IS_MUST_SELECT_GROUP);
    
    if (!tenant) return null;

    if (ldapIsMustSelectGroup === 'true') {
      return <Redirect exact from={ROOT} to={LDAP_GROUP_SELECT} />
    }

    return (
      <Redirect exact from={ROOT} to={DASHBOARD} />
    )
  }
}

const mapStateToProps = ({ tenant }) => ({ tenant });

export default compose(
  connect(mapStateToProps, { ...TenantCreators })
)(RouteRedirector);