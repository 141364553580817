import { compose } from 'react-recompose/dist/Recompose.cjs';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Creators as AnomalyManagementCreators } from '../../store/ducks/anomaly_management';
import { alpha } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Paper,
} from '@material-ui/core';
import { CloudDownload as DownloadIcon, Send as SendIcon, Search as SearchIcon } from '@material-ui/icons';
import Select from 'react-select';
import { noBreakLineSpace, csvStringFromArrayOfObjects } from '../../utils';
import { colorHexOptions, EmptyText, CenteredDiv } from './styles';
import './styles.css';

const INITIAL_TABLE_LIMIT = 5000;
let table_total_elements_limit = INITIAL_TABLE_LIMIT;

class AnomaliesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'Id',
      selected: [],
      page: 0,
      rowsPerPage: 25,
      activeTypeSelected: null,
      filtersSelected: [{ label: 'Aguardando verificação', value: 'Aguardando verificação' }],
    };
    this.rowsPerPageOptions = [5, 10, 25, 50, 75, 100];
    this.actionsCheckbox = [
      {
        name: 'Confirmar',
        disableFunc: value => value !== 'Aguardando verificação',
        color: colorHexOptions.green,
      },
      { name: 'Rejeitar', disableFunc: value => value !== 'Aguardando verificação', color: colorHexOptions.red },
      {
        name: 'Reverter',
        disableFunc: value => value === 'Aguardando verificação',
        color: colorHexOptions.yellow,
      },
    ];
  }

  onDownloadButtonClick = _event => {
    const { activeTypeSelected } = this.state;
    const { header, anomaly_data } = this.props;
    const csv = csvStringFromArrayOfObjects(header, anomaly_data);

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = `Dados_${activeTypeSelected.value}_${new Date().toLocaleDateString()}.csv`;
    hiddenElement.click();
    hiddenElement.remove();
  };

  onSendButtonClick = _event => {
    const { selected, activeTypeSelected, filtersSelected, rowsPerPage } = this.state;

    this.props.postAnomalies({
      selected,
      active_type: activeTypeSelected.value,
      table_limit: table_total_elements_limit,
      items_per_page: rowsPerPage,
      filters_selected:
        filtersSelected && filtersSelected.length ? filtersSelected.map(filter => filter.value) : [],
    });
    this.setState({ selected: [] });
  };

  isSelected = (key, value) => this.state.selected.find(item => item[key] === value);

  checkFilters = item => (!item.Id ? false : true);

  getNewData = (defaultPage = true) => {
    const { page, rowsPerPage, activeTypeSelected, filtersSelected } = this.state;

    if (activeTypeSelected) {
      this.props.getAnomalies({
        active_type: activeTypeSelected.value,
        table_limit: table_total_elements_limit,
        page: !defaultPage ? page : 0,
        items_per_page: rowsPerPage,
        filters_selected: filtersSelected?.map(filter => filter.value),
      });
      this.setState({ selected: [] });
    }
  };

  handleSelectChange = (key, value, getNewDataBool = false) => {
    this.setState({ [key]: value, page: 0, order: 'asc', orderBy: 'Id' }, getNewDataBool ? this.getNewData : null);
  };

  handleClickCheckbox = (_event, id, name) => {
    if (!id) return;
    const { selected } = this.state;
    const elementFound = selected.find(element => element.id === id);
    let newSelected = [...selected];

    if (!elementFound) {
      newSelected.push({ id, action: name });
    } else {
      newSelected = newSelected.filter(element => element.id !== id);
    }

    this.setState({ selected: newSelected });
  };

  handleSelectAllClick = (event, array, action) => {
    if (event.target.checked) {
      const newSelecteds = array?.map(item => ({
        id: item.Id,
        action,
      }));

      this.setState({ selected: newSelecteds });

      return;
    }

    this.setState({ selected: [] });
  };

  handleChangePage = (_event, newPage) => {
    this.setState({ page: newPage }, () => this.getNewData(false));
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 }, this.getNewData);
  };

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === 'asc';

    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  renderTableHead = funcProps => {
    function checkIfFieldCanBeSorted(value) {
      const nonSortable = [];

      return nonSortable.indexOf(value) === -1;
    }

    const { sortableTable } = this.props;
    const { onSelectAllClick, order, orderBy, onRequestSort, titles, dataToDisplay } = funcProps;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    const verifyArray = dataToDisplay.filter(item => item.Status === 'Aguardando verificação');
    const revertArray = dataToDisplay.filter(item => item.Status !== 'Aguardando verificação');
    const verifyCheckboxDisabled = !verifyArray || !verifyArray.length;
    const revertCheckboxDisabled = !revertArray || !revertArray.length;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            Confirmar
            <Checkbox
              style={{ color: !verifyCheckboxDisabled ? colorHexOptions.green : '' }}
              disabled={verifyCheckboxDisabled}
              onChange={event => onSelectAllClick(event, verifyArray, 'Confirmar')}
              inputProps={{
                'aria-label': 'Confirmar todos',
              }}
            />
          </TableCell>
          <TableCell padding="checkbox">
            Rejeitar
            <Checkbox
              style={{ color: !verifyCheckboxDisabled ? colorHexOptions.red : '' }}
              disabled={verifyCheckboxDisabled}
              onChange={event => onSelectAllClick(event, verifyArray, 'Rejeitar')}
              inputProps={{
                'aria-label': 'Rejeitar todos',
              }}
            />
          </TableCell>
          <TableCell padding="checkbox">
            Reverter
            <Checkbox
              style={{ color: !revertCheckboxDisabled ? colorHexOptions.yellow : '' }}
              disabled={revertCheckboxDisabled}
              onChange={event => onSelectAllClick(event, revertArray, 'Reverter')}
              inputProps={{
                'aria-label': 'Reverter todos',
              }}
            />
          </TableCell>
          {titles.map((t, index) => (
            <TableCell
              key={`head_${t}_${index}`}
              align="left"
              padding="normal"
              sortDirection={orderBy === t ? order : false}
            >
              {sortableTable && checkIfFieldCanBeSorted(t) ? (
                <TableSortLabel
                  active={orderBy === t}
                  direction={orderBy === t ? order : 'asc'}
                  onClick={createSortHandler(t)}
                >
                  {noBreakLineSpace(t)}
                  {orderBy === t ? <Box component="span" /> : null}
                </TableSortLabel>
              ) : (
                <>{noBreakLineSpace(t)}</>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  renderTableCells = (data, mainKey, isItemSelected) => (
    <TableRow id={data.Id} key={mainKey}>
      {this.actionsCheckbox?.map((item, index) => (
        <TableCell key={`action-${item.name}-${index}-${data.Id}`} padding="checkbox">
          <Checkbox
            checked={isItemSelected?.action === item.name}
            disabled={item.disableFunc(data.Status) || !data.Id}
            onChange={event => this.handleClickCheckbox(event, data.Id, item.name)}
            style={{ color: item.disableFunc(data.Status) || !data.Id || !item.color ? '' : item.color }}
          />
        </TableCell>
      ))}
      {Object.values(data).map((value, idx) => (
        <TableCell key={`body-item-${value}-${idx}-${data.Id}`}>{this.renderCell(value)}</TableCell>
      ))}
    </TableRow>
  );

  renderCell = value => {
    if (value === 'n_informado' || value === 'nao informado' || value === 'nan' || !noBreakLineSpace(value))
      value = 'Não informado';

    const style = {
      fontWeight: value === 'Rejeitado' || value === 'Confirmado' ? 'Bold' : 'Normal',
      color: value === 'Rejeitado' ? colorHexOptions.red : value === 'Confirmado' ? colorHexOptions.green : '',
    };

    return (
      <Typography
        style={
          value !== 'Não disponível para este ativo' ? style : { fontWeight: 'Bold', color: colorHexOptions.blue }
        }
        variant={value !== 'Não disponível para este ativo' ? 'body1' : 'overline'}
        p="p"
      >
        {noBreakLineSpace(value)}
      </Typography>
    );
  };

  descendingComparator = (a, b, orderBy) => {
    const comparisonA = a[orderBy] ? noBreakLineSpace(a[orderBy]) : '';
    const comparisonB = b[orderBy] ? noBreakLineSpace(b[orderBy]) : '';

    if (comparisonB < comparisonA) return -1;
    if (comparisonB > comparisonA) return 1;

    return 0;
  };

  getComparator = (order, orderBy) =>
    order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);

  getSortedArray = (array, comparator) => {
    if (array && array.length > 1) {
      const stabilizedThis = [];

      array.forEach((el, index) => stabilizedThis.push([el, index]));

      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        return order ? order : a[1] - b[1];
      });

      return stabilizedThis?.map(el => el[0]);
    }

    return array;
  };

  render = () => {
    const { order, orderBy, page, rowsPerPage, filtersSelected, activeTypeSelected, selected } = this.state;
    const {
      header,
      anomaly_data,
      sortableTable,
      tableTitle,
      activeTypeOptions,
      statusFilterSelectOptions,
      isLoading,
      totalDataLength,
    } = this.props;
    let dataToDisplay = sortableTable
      ? this.getSortedArray(anomaly_data, this.getComparator(order, orderBy))
      : anomaly_data;
    dataToDisplay = dataToDisplay?.filter(this.checkFilters);

    return (
      <Box sx={{ width: '100%', padding: '1.5vw' }}>
        <Paper sx={{ width: '100%', mb: 2 }} style={{ minHeight: '500px', overflowX: 'auto' }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              bgcolor: theme => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }}
            style={{ zIndex: 10 }}
          >
            <Grid container spacing={2} style={{ margin: '1vh 0' }}>
              <Grid item xs={8}>
                <Typography id="tableToolbar" variant="h4" component="h1">
                  {tableTitle}
                </Typography>
              </Grid>
              <Grid item xs>
                <Button
                  variant="contained"
                  className="download-button"
                  endIcon={<DownloadIcon />}
                  onClick={this.onDownloadButtonClick}
                  disabled={!activeTypeSelected || !totalDataLength || isLoading}
                >
                  {noBreakLineSpace('Download da tabela')}
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  variant="contained"
                  className="send-button"
                  endIcon={<SendIcon />}
                  onClick={this.onSendButtonClick}
                  disabled={!activeTypeSelected || !totalDataLength || isLoading || !selected || !selected.length}
                >
                  {noBreakLineSpace('Enviar verificação')}
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Select
                  noOptionsMessage={_ => 'Sem dados'}
                  options={activeTypeOptions}
                  value={activeTypeSelected}
                  isDisabled={isLoading}
                  placeholder={'Selecione um ativo'}
                  onChange={value => this.handleSelectChange('activeTypeSelected', value, true)}
                />
              </Grid>
              <Grid item xs={5}>
                <Select
                  noOptionsMessage={_ => 'Sem dados'}
                  options={statusFilterSelectOptions}
                  value={filtersSelected}
                  isDisabled={isLoading}
                  isMulti
                  isClearable
                  placeholder={'Selecione um ou mais filtros de status'}
                  onChange={value => this.handleSelectChange('filtersSelected', value)}
                />
              </Grid>
              <Grid item xs={0.5}>
                <Tooltip title="Pesquisar">
                  <IconButton
                    disabled={isLoading || !activeTypeSelected}
                    onClick={this.getNewData}
                    style={{ padding: 10 }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
          {activeTypeSelected ? (
            isLoading ? (
              <CenteredDiv>
                <CircularProgress size={80} style={{ color: colorHexOptions.blue }} />
              </CenteredDiv>
            ) : totalDataLength ? (
              <>
                <TableContainer style={{ maxHeight: '70vh' }}>
                  <Table stickyHeader aria-labelledby="tableTitle" size="medium">
                    {this.renderTableHead({
                      order,
                      orderBy,
                      onRequestSort: this.handleRequestSort,
                      onSelectAllClick: this.handleSelectAllClick,
                      titles: header,
                      dataToDisplay,
                    })}
                    <TableBody>
                      {dataToDisplay?.map((row, index) =>
                        this.renderTableCells(row, `table-body-${index}`, this.isSelected('id', row.Id))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={this.rowsPerPageOptions}
                  count={totalDataLength}
                  component="footer"
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  labelDisplayedRows={({ from, to, count }) => {
                    if (to >= table_total_elements_limit) table_total_elements_limit += INITIAL_TABLE_LIMIT;

                    return count >= table_total_elements_limit
                      ? `${from}-${to} de ${table_total_elements_limit} +`
                      : `${from}-${to} de ${count}`;
                  }}
                  labelRowsPerPage="Ativos por página:"
                  backIconButtonText="Página anterior"
                  nextIconButtonText="Próxima página"
                />
              </>
            ) : (
              <EmptyText color={colorHexOptions.grey}>Não foram detectadas anomalias</EmptyText>
            )
          ) : (
            <EmptyText color={colorHexOptions.black}>Selecione um ativo</EmptyText>
          )}
        </Paper>
      </Box>
    );
  };
}

const mapStateToProps = ({ anomaly_management }) => ({ anomaly_management });

export default compose(connect(mapStateToProps, { ...AnomalyManagementCreators }))(AnomaliesList);
